import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import {takeWhile} from 'rxjs/operators';
import isEqual from 'lodash/isEqual';

import { Product } from '../../../models/product.model';
import { Order } from '../../../models/order.model';
import { OrderItem } from '../../../models/order-item.model';
import { PREORDER_TYPE } from '../../../constants/order-types';
import { Address } from '../../../models/address.model';
import { ProductService } from '../../../services/product.service';
import { Budget } from '../../../models/budget.model';
import { OrderItemDelivery } from '../../../models/order-item-delivery.model';
import { AuthService } from '../../../services/auth.service';
import { OrderItemSku } from '../../../models/order-item-sku.model';
import { SKU } from '../../../models/sku.model';
import { OrderService } from '../../../services/order.service';
import { EntityService } from '../../../services/entity.service';
import { BudgetService } from '../../../services/budget.service';
import { ConfigService } from '../../../services/config.service';
import { AddressService } from '../../../services/address.service';
import { VOID_STATE } from '../../../constants/order-states';
import { TranslateService } from '../../../services/translate.service';
import {ModalService} from '../../../services/modal.service';
import { Customization } from '../../../interfaces/customization';
import {OrderItemService} from '../../../services/order-item.service';
import {
    computeAvailableAddresses,
    computeMaxQuantity,
    validateOrderItem,
    validateWbsForUnique,
} from '../../../shared/helpers';
import {isEmptyArray} from '../../../shared/utils';
import {PaymentService} from '../../../services/payments.service';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';
import {OrderItemRemove} from '../../../interfaces/order-item-remove';
import { Currency } from '../../../interfaces/currency';
import {MultiTenantService} from '../../../services/multi-tenant.service';


const DETAILED_ITEM = 'detailed';
const LIST_ITEM = 'list-item';
const GRID_ITEM = 'grid-item';


@Component({
    selector: 'app-product-order-item',
    templateUrl: './product-order-item.component.html',
    styleUrls: ['./product-order-item.component.scss']
})
export class ProductOrderItemComponent implements OnInit, OnDestroy {

    purchaseResult: {added: boolean, updated: boolean, deleted: boolean, hasError: boolean}
        = {added: false, updated: false, deleted: false, hasError: false}
    private alive = true;
    _order: Order;
    _orderItem: OrderItem;
    _originalOrderItem: OrderItem;
    productPrice: number;
    aggregatedQuantity: number;
    // groupAggregatedQuantity: number;
    customVariationTotalQuantity: number;

    totalCost = 0;

    dpQuantity = 0;
    budgets: Budget[] = [];
    selectedBudget: Budget =  null;
    selectedBudgetId: number = null;


    dpSummaryData: any;
    loadingSummaryData = false;
    decisionPointId: string;

    updatingFavorites = false;

    removingDeliveries = false;
    refreshingTrackingStatus = false;

    initialized = false;

    private initializing = false;

    checkingLease = false;
    leaseResult: string;

    private noAvailableShippingMethods = false;

    @Input() product: Product;

    @Input() viewType = DETAILED_ITEM; // detailed | list-item | grid-item

    @Input() showBudget = false;

    @Input() showEditControls = false;

    @Input() showHideItemButton = false; // only for readonly mode so far

    @Input() showTrackingInfo = false;

    @Input() showReorderButton = false;

    @Input() showTotals = true;

    @Input() showVariations = true;

    @Input() readonly = false;

    @Input() modal = false; // true if component called from modal dialog

    @Input() hideFinancials = false;

    @Input() delivery: OrderItemDelivery;

    @Input() paidQuantity: number;

    @Input() removing = false;

    @Output() onRemove = new EventEmitter<OrderItemRemove>();
    @Output() onRemoveRestrictedItems = new EventEmitter<Order>();

    @Output() onUpdate = new EventEmitter<OrderItem>(); // output event for modal dialog to close

    @Output() close = new EventEmitter<void>();

    @Output() changed = new EventEmitter<OrderItem>();

    @Output() changeOrderItemVisibility = new EventEmitter<boolean>();

    @Input() set order(o: Order) {
        if (!this.initializing && o  && o.loaded) {

            if (!this._order || !this._order.equal(o)) {
                this._order = o;
                if (this.product) {
                    this._orderItem = null;
                    this.setupOrderItem();
                }
            } else if (this._order.id === o.id) {
                this._order = o;
            }
        }
    };

    @Input() set orderItem( i: OrderItem) {
        if (!this._order || !this._order.loaded) {
            return;
        }

        if (!i) {
            return;
        }

        if (!this._orderItem || this._orderItem.id !== i.id) {
            this._orderItem = i;
            this._originalOrderItem = new OrderItem(i);
            this.product = this._orderItem.product;

            if (!this.initialized) {
                this.setupOrderItem();
            }
        } else {
            this._orderItem = i;
            this._originalOrderItem = new OrderItem(i);
            this.product = this._orderItem.product;
        }
    }




    constructor(private authService: AuthService,
                private addressService: AddressService,
                private productService: ProductService,
                private orderService: OrderService,
                private entityService: EntityService,
                private budgetService: BudgetService,
                private configService: ConfigService,
                private toastr: ToastrService,
                private translateService: TranslateService,
                private modalService: ModalService,
                private orderItemService: OrderItemService,
                private router: Router,
                private paymentService: PaymentService,
                private mobileCheck: MobileCheckServiceService,
                private multiTenantService: MultiTenantService
    ) {
    }

    ngOnInit(): void {
        this.setupOrderItem();

        this.initProductUpdate();

    }

    ngOnDestroy() {
        this.alive = false;
    }

    // defined size of product image depends on viewType
    get imageSize(): string {
        switch (this.viewType) {
            case DETAILED_ITEM:
                return 'none';
            case LIST_ITEM:
                return 'small';
        }
    }

    get canAddAddress(): boolean {
        if (!this.product.hasVariations && !this.isDecisionPointOrder && this.canPurchase) {
            if (this.product.isCustomizable) {
                if (this.product.customization.allow_multiple !== true) {
                    return false; // cannot add another address
                }
            }

            if (this.hasAvailableAddressesLeft) {
                return true;
            }

            return this.authService.canAddAddress;
        }

        return false;
    }

    get hasAvailableAddressesLeft(): boolean {
        return !isEmptyArray( computeAvailableAddresses(this.addressService.addresses, this._order, this._orderItem));
    }


    get addressLabel(): string {
        if (this.hasAvailableAddressesLeft)  {
            return 'Add another address';
        }
        return 'Add new address';
    }

    get isBudgetExhausted (): boolean {
        if (this.budgets?.length !== 1) {
            return false;
        }
        return  this.budgets[0].isExhausted && this._orderItem.quantity >= this._orderItem.init_quantity;
    }

    private async setupOrderItem() {
        if (!this._order) {
            return;
        }


        const userRole = {canOrder: this.authService.canOrder, isEmployee: this.authService.isEmployee};
        const presetAddresses: Address[] = this._order.presetAddresses;

        if (!this._orderItem) {
            this._orderItem = new OrderItem(this._order.getItemForProduct(this.product, {
                defaultDelivery: null, presetAddresses, userRole
            }));
        }


        this.initializing = true;

        if (this._orderItem.skus.length === 0) {
            this.product.skus.forEach((sku: SKU) => {
                this._orderItem.getVariationSKU(sku.id);
            });
        }

        this.computeAggregatedQuantity(this._orderItem.product);

        if (!this.isDecisionPointOrder && this._orderItem.deliveries.length === 0) {
            this._orderItem.deliveries.push(new OrderItemDelivery({
                type: 'SHIPPING',
                method: '',
                quantity: 0,
                data: {}
            }));
        }


        this.computeTotalQuantity();
        this.computeProductPrice();
        this.computeTotalCost();


        // Select first address
        if (this._orderItem.deliveries.length > 0 && !this._orderItem.deliveries[0].addressDefined) {
            const userRoles = {canOrder: this.authService.canOrder, isEmployee: this.authService.isEmployee};
            if (this._order.isWSLR) {
                // set default WLS address
                const entityAddress = Address.fromEntityLocation(this._order.entity.getDefaultShippingLocation());
                if (entityAddress) {
                    this._orderItem.deliveries[0].setAddress(entityAddress, this.authService.user, this._order.isCustom,
                        userRoles);
                }
                // this.setInitialDeliveryAddress(this._orderItem.deliveries[0], entityAddress);
            } else  {
                // set user's default address
                const availableAddresses = computeAvailableAddresses(this.addressService.allShipmentAddresses, this._order, this._orderItem);

                const addresses = this.product.isInternationalShipmentAllowed ?
                    availableAddresses : availableAddresses.filter( a => a.isInternational !== true);
                if (!isEmptyArray(addresses)) {

                    // find first default
                    const address = addresses.find( a => a.isDefault);

                    if (address) {
                        this._orderItem.deliveries[0].setAddress(address, this.authService.user, this._order.isCustom,
                            userRoles);
                    }

                }
            }


        }

        this.budgets = this.budgetService.getBudgetsForOrderItem(this._orderItem);
        if (this.budgets.length > 0) {
            if (this._orderItem.financials_total.budget_id) {
                // this._orderItem.financials_total.budget_id = this.budgets[0].id;
                if (this._orderItem.financials_total.budget_id && !this._orderItem.budgetItem) {
                    this._orderItem.budgetItem = this.budgetService.getBudgetByOrderItem(this._orderItem);
                }
                this.selectedBudget = this._orderItem.budgetItem;
                this.selectedBudgetId = this.selectedBudget?.id;
            } else {
                const hardlimitBudget = this.budgetService.getHardlimitBudgetForProduct(this.product);
                if (hardlimitBudget) {
                    this.selectedBudget = hardlimitBudget;
                    this.selectedBudgetId = this.selectedBudget.id;
                    this._orderItem.financials_total.budget_id = hardlimitBudget.id;
                    this._orderItem.budgetItem = hardlimitBudget;
                }
            }
        }

        this._originalOrderItem = new OrderItem(this._orderItem);


        if (!this.readonly &&
            this.viewType !== 'grid-item' &&
            this._order.isWSLR !== true &&
            this._order.isDecisionPoint &&
            !this.product.hasVariations) {

            if (this.decisionPointId !== this._order.attr_decision_point_id) {
                this.decisionPointId = this._order.attr_decision_point_id;
                this.loadingSummaryData = true;
                this.dpSummaryData = await this.productService.getDecisionPointData(this.decisionPointId, this._orderItem)
                    .toPromise();
                this.loadingSummaryData = false;
            }
        }

        this.initialized = true;
        this.initializing = false;
    }


    private computeProductPrice() {
        // const quantity = (this._order.type === PREORDER_TYPE) ?
        //      this._orderItem.totalQuantity : this._orderItem.quantity;

        // this.productPrice = this.product.skus[0].getPrice(quantity);
        this.productPrice = this._orderItem.getProductPrice();
    }

    private computeTotalQuantity() {
        this._orderItem.quantity = this.getTotalQty();

        this.dpQuantity = this._orderItem.quantity;

        if (!this.product.hasVariations) {
            this._orderItem.skus[0].quantity = this._orderItem.quantity;
        }

        if (this._orderItem.product.isCustomizable) {
          this.customVariationTotalQuantity = 0;
          this._orderItem.skus.forEach((sku: OrderItemSku) => {
              this.customVariationTotalQuantity += sku.quantity;
          });
        }
    }

    private computeTotalCost() {
        this.totalCost  = this._orderItem.financials_total.subtotal;
        // calculate discount if any
        this.totalCost = ProductService.computeDiscountPrice(this.totalCost, this.product, this._orderItem.hasTrimesterBudget);

        // if (this.product.hasVariations) {
        //     this.totalCost = 0;
        //
        //     this._orderItem.skus.forEach((sku: OrderItemSku) => {
        //         const parentSKU = this.product.getSKUbyID(sku.sku_id);
        //         if (parentSKU) {
        //             const quantity = sku.quantity;
        //             // sku.price = parentSKU.getPrice(quantity) * sku.quantity;
        //             this.totalCost += parentSKU.getPrice(quantity) * sku.quantity;
        //         }
        //     });
        // } else {
        //     this.totalCost = this._orderItem.quantity * this.productPrice;
        // }
        //

    }

    private computeAggregatedQuantity(product: Product) {
        this.aggregatedQuantity = product.getAggregatedQuantity();

        // this.groupAggregatedQuantity = (this.product.skus[0].groupMinQTY > 0 && this.product.skus[0].group_aggregated_quantity > -1)
        //     ? this.product.skus[0].group_aggregated_quantity : -1;

    }

    addAnotherAddress() {
        if (this.hasAvailableAddressesLeft) {
            this._orderItem = this.orderItemService.addAnotherAddress(this._orderItem, this._order);
            this.changed.emit(this._orderItem);
        } else {
            this.modalService.editAddress(null, true).then( result => {
                if (result) {
                    this._orderItem = this.orderItemService.addAnotherAddress(this._orderItem, this._order);
                    this.changed.emit(this._orderItem);
                }
            })

        }
    }


    onUpdateDeliveryQuantity() {
        this.computeTotalQuantity();
        this.computeProductPrice();
        this.computeTotalCost();
    }


    private getTotalQty() {
        return this._orderItem.deliveries.reduce((total, delivery) => total + Number(delivery.quantity), 0);
    }

    private reduceQty(availableQty: number) {
        this._orderItem.deliveries.forEach(delivery => {
            delivery.quantity = (delivery.quantity < availableQty) ? delivery.quantity : availableQty;
            availableQty -= delivery.quantity;
        });
    }

    saveWithDelay() {
        setTimeout(() => {
            this.saveChanges();
        }, 100);
    }

    saveChanges() {
        this.clearSaveResult();

        const errorMsg  =  validateOrderItem(this._orderItem);
        if (errorMsg) {
            this.toastr.warning(errorMsg, 'Warning');
            return;
        }

        const internalOrderValidationError  =  validateWbsForUnique(this._order, this._orderItem, this.configService.features);
        if (!!internalOrderValidationError) {
            this.toastr.warning(internalOrderValidationError, 'Warning');
            return;
        }

        if (this.isNotSameCurrency) {
            this.toastr.warning('This item is not in the same currency. Please create a new cart');
            return;
        }

        this.clearSaveResult();
        this.orderItemService.saveOrderItem(this._order, this._orderItem, false, this._originalOrderItem).subscribe(result => {
            if (result) {
                this.purchaseResult = result.purchaseResult;

                if (this.purchaseResult.hasError) {
                    return;
                }

                if (result.orderItem) {
                    this._orderItem = result.orderItem;
                    this._orderItem.changed = false;
                    this._originalOrderItem = new OrderItem(this._orderItem);
                    this.changed.emit(null);
                } else {
                    this._orderItem = new OrderItem(this._originalOrderItem);
                }
                if (result.budgets) {
                    this.budgets = result.budgets;
                }

                if (result.order) {
                    this._order = result.order;
                }
                this.product = this._orderItem.product;
                this.computeProductPrice();
                this.computeTotalCost();

            } else {
                this._orderItem = new OrderItem(this._originalOrderItem);
            }
        });
    }


    /**
     * checks whether item can be purchased
     * in case of BUYING_WINDOW project  ow_purchasable == false - purchase is not available
     */
    get canPurchase(): boolean {

        // if (this._orderItem.validateAddressesAndShipmentMethodsError()) {
        //     return  false; // cannot save order item when shipping method is not defined
        // }

        return !this.paymentService.hasFailedTransactions
            && !this.isBudgetExhausted
            && this.orderService.canPurchase(this._order, this._orderItem);
    }

    get canAddQuantity(): boolean {
        return !this.paymentService.hasFailedTransactions;
    }


    private isWSLROrder(): boolean {
        return this._order.isWSLR
    }

    /**
     * get actual deliveries that not removed
     * @param item order item
     */
    getActualDeliveries() {
        return this._orderItem.getActualDeliveries();
    }

    updateOrderItemVariation(sku) {
        this.clearSaveResult();

        this._orderItem.validateVariationQuantity(sku);

        this.computeTotalQuantity();
        this.computeTotalCost();
        this._orderItem.changed = true;
        this.changed.emit(this._orderItem);
    }


    showDpModal() {
        this.clearSaveResult();
        this.modalService.showDecisionPoint(this._order.decisionPointData, this.dpSummaryData).then( quantity => {
            if (quantity > 0) {
                this.calculateDP(quantity);
            }
        });
    }

    calculateDP(dpQuantity) {

        this.orderItemService.calculateDecisionPoints(this._order, this._orderItem, dpQuantity).subscribe( orderItem => {
            this._orderItem = orderItem;
        });
    }



    get addresses(): Address[] {
        return this.addressService.addresses;
    }

    get canSeeBudgets(): boolean {
        return this.authService.canSeeBudgets
    }

    get isBudgetsVisible(): boolean {
        return this.canSeeBudgets && this.showBudget && !isEmptyArray(this.budgets);
    }

    get canOrder(): boolean {
        return this.authService.canOrder;
    }

    get isDecisionPointOrder(): boolean {
        return this._order?.isDecisionPoint || false;
    }

    get cancelled(): boolean {
        return this._orderItem.state === VOID_STATE;
    }

    onHandleRemove(hideConfirmation = false) {
        this.onRemove.emit({orderItem: this._orderItem, hideConfirmation});
    }

    onHandleRemoveRestricted() {
        this.onRemoveRestrictedItems.emit(this._order);
    }

    handleEdit() {
        if (this.mobile) {
            const product = this._orderItem.product;
            this.router.navigate([product.productURL]);
        } else {
            this.orderItemService.editOrderItemModal(this._order, this._orderItem).then( result => {
                    if (result) {
                        this._orderItem = result
                        this.onUpdate.emit(this._orderItem);
                    }
                }
            );
        }
    }


    // shows total cost including delivery cost, computed on backend
    get financialTotalCost(): number {
        return this.cancelled !== true ? this._orderItem.totalCost : 0;
    }

    get taxes(): number {
        return (this.cancelled !== true) ? this._orderItem.taxes : 0;
    }

    get canDisplayEditControls(): boolean {
        return (this.showEditControls === true
            // && this.cancelled === false
            && this._order.type !== 'SUMMARY_TYPE'
            && this.authService.canEditOrder(this._order)
        );
    }


    get canEditOrder(): boolean {
        return this.canDisplayEditControls &&  this.cancelled === false;
    }


    getVariationLabel(sku: SKU): string[] {
        return  sku.sortedAttributes.map( attribute => {
            const label = this.translateService.translate(attribute.label);

            return `${label}: ${attribute.options[0].label}`;
        });
    }

    onCustomizationChanged() {
        this.saveChanges();
    }

    addVariationsCustomization() {
        this.clearSaveResult();

        if (!Array.isArray(this._orderItem.deliveries) || this._orderItem.deliveries.length === 0) {
            return;
        }

        const delivery = this._orderItem.deliveries[0];

        const data: {maxQTY: number; productCustomization: Customization;
            deliveryCustomizations: Customization[];
            hasVariations: boolean} = {
            maxQTY: this._orderItem.maxAvailableQuantity,
            productCustomization: this._orderItem.product.customization,
            deliveryCustomizations: delivery.customizations,
            hasVariations: this._orderItem.product.hasVariations};

        this.modalService.showProductCustomizationModal( data ).then( (result: Customization[]) => {
            if (result) {

                // const customizationQuantity = getCustomizationsTotal(result);
                const minQuantity = this.product.customization.custom_min_qty;

                this.product.skus.forEach( sku => {
                    const itemSKU = this._orderItem.getVariationSKU(sku.id);

                    // FUSE-1496
                    // if (itemSKU.quantity > 0 && itemSKU.quantity < minQuantity) {
                    //     itemSKU.quantity = minQuantity;
                    // }

                    if (this.product.inventoryType) {
                        const availableQty = sku.available_quantity + itemSKU.init_quantity;
                        if (itemSKU.quantity > availableQty) {
                            itemSKU.quantity = availableQty;
                        }
                    }
                    this._orderItem.setVariationSKU(itemSKU);
                });

                const deliveryQuantity = this._orderItem.skus.reduce( (total, skuItem) => total += skuItem.quantity, 0);
                // in case of variations only one delivery
                delivery.quantity = deliveryQuantity;
                result[0].custom_qty = deliveryQuantity;
                delivery.customizations = result;

                this.computeTotalQuantity();
                this.computeTotalCost();
            }
        });

    }

    get hasCustomization(): boolean {
        return this._orderItem.product.isCustomizable;
    }

    get canCustomize(): boolean {
        return this.hasCustomization &&
            (this._order && this._order.supportsCustomization && !this.paymentService.hasFailedTransactions) ;
    }


    get variationCustomizations(): Customization[] {
        if (!this._orderItem.product.hasVariations) {
            return null;
        }

        if (!this.hasCustomization) {
            return null;
        }


        return (this._orderItem.deliveries && this._orderItem.deliveries.length > 0) ?
            this._orderItem.deliveries[0].customizations : null;
    }


    viewPriceDetails() {
        this.modalService.showPriceDetails(null, this._orderItem);
    }

    onDeliveryChanged() {
        this.onUpdate.emit(this._orderItem);
    }

    onToggleFavorites(product: Product) {
        this.updatingFavorites = true;
        this.productService.toggleFavorites(product).subscribe( () => {
            this.updatingFavorites = false;
        });
    }

    onClose() {
        if (this.modal) {
            this.close.emit();
        }
    }

    private initProductUpdate() {
        if (this.viewType === DETAILED_ITEM) {
            this.productService.populateProduct(this.product);
        }

        this.productService.updatedProduct.pipe( takeWhile(() => this.alive)).subscribe( product => {
            if (this.product && this._orderItem) {
                if (product && this._orderItem.product.id === product.id) {
                    this.product = product;
                    this._orderItem.product = product;
                }
            }
        });
    }

    get showMinOrderInHeader(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.product.window_id) {
            return false;
        }

        if (!this.product.hasVariations) {
            return true;
        }

        return this.product.getGroupMinQuantity() > 0;
    }


    showMinOrderInBody(sku: SKU): boolean {
        if (this.readonly) {
            return false;
        }
        if (!this.product.isBuyingWindow) {
            return false;
        }

        if (this.product.hasVariations) {
            const showMaxQuantity =   computeMaxQuantity(this.product, sku) > 0 || this.product.has_inventory;
            return !(this.product.getGroupMinQuantity() > 0) || showMaxQuantity;
        }
        return true;
    }

    get isProductRecommended(): boolean {
        return !this.readonly && this.orderItemService.isProductRecommended(this.product, this._order);
    }


    get canShowProductPurchaseDetails(): boolean {
        if (!this.product || this.product.isBuyingWindow !== true) {
            return false;
        }
        return true;
        // return this.authService.isWholeSaler || (this.authService.isEmployee && this._order?.isWSLR);
    }

    redirectToFullProduct() {
        let url = this.product.productURL;
        if (this.product.isBuyingWindow) {
            if (this._order.window_id !== this.product.window_id) {
                url = `/buying-window/${this._order.window_id}/products/${this.product.getSlug()}`;
            }
        }
        this.router.navigate([url]).then( () => this.onClose());
    }

    redirectToShoppingCart() {
        this.router.navigate([`/cart/${this._order.id}`]).then(() => this.onClose());
    }

    get orderItemUpdating(): boolean {
        return this.orderItemService.updating;
    }

    onOrderItemChanged(orderItem: OrderItem) {
        if (orderItem) {
            this.clearSaveResult();
            this._orderItem = new OrderItem(orderItem);
            this._orderItem.changed = true;
            this._orderItem.computeAvailableAddresses(this._order.isWSLR);
            // this._order.updateOrderItems(orderItem);
            this.orderItemService.prefetchCapexForEntity(this._orderItem);

            // const errorMsgs  =  this._orderItem.validateAddressesAndShipmentMethodsError();
            // if (errorMsgs) {
            //     this.toastr.warning(errorMsgs);
            //     return;
            // }
            this.changed.emit(this._orderItem);
        }
    }

    onOrderItemChangeVisibility(val: boolean) {
        this._orderItem.hidden = val;
        this.changeOrderItemVisibility.emit(val);
    }

    public reorderItem() {
        if (!this._order || !this.product) {
            return;
        }
        this.orderItemService.showProductInModal(this.product, this._order, this.showReorderButton === true).subscribe(() => {});
    }

    refreshTrackingStatus() {

        if (this.refreshingTrackingStatus) {
            return; // avoid several clicks
        }

        this.refreshingTrackingStatus = true;
        this.orderService.getTrackingData(this._order).subscribe(retOrder => {
            if (retOrder) {
                this._order =  retOrder;
                this._orderItem = this._order.findOrderItem(this._orderItem.id);
            }
            this.refreshingTrackingStatus = false;
        });
    }


    showDPFaqDialog() {
        this.modalService.showDecisionPointFaq(this._order.attr_decision_point_name);
    }

    private clearSaveResult(): void {
        this.purchaseResult = {added: false, updated: false, deleted: false, hasError: false};
    }

    changeBudget(budget: Budget) {
        this.selectedBudget = budget;
        this.selectedBudgetId = this.selectedBudget.id;
        this._orderItem.financials_total.budget_id = budget.id;
    }

    checkLeaseStatus() {
        this.checkingLease = true;
        this.leaseResult = '';
        this.orderItemService.checkLeaseStatus().subscribe( data => {
            this.checkingLease = false;
            this.leaseResult = data;
        });
        return false;
    }


    get canShowDPModal(): boolean {
        return !this.paymentService.hasFailedTransactions;
    }

    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }

    get mobileDevice(): boolean {
        return this.mobileCheck.mobileDevice;
    }

    get showLeaseWarning(): boolean {
        return this.orderItemService.shouldShowLeaseWarning(this.product);
    }

    get leaseAgreementUrl(): string {
        // return `https://www.abmarketing.com/MerchTracking/LeaseAgreement.aspx?OnSubmitReturnUrl=${encodeURIComponent(window.location.href)}`;
        return '';
    }

    get isTaxExemptAllowed(): boolean {
        return this.authService.user.isTaxExemptAllowed || this._order.isWSLR;
    }

    get taxExemptOrderHasTaxes(): boolean {
        if (!this.isTaxExemptAllowed) {
            return false;
        }

        if (!this._order.taxExempt ) {
            return false;
        }

        if (this._orderItem.taxes === 0) {
            return false;
        }

        return !this._orderItem.taxExemptIncluded;

    }


    get taxExemptReason(): string {
        if (!this.taxExemptOrderHasTaxes) {
            return  '';
        }

        return this._orderItem.taxExemptReason;
    }

    get restricted(): boolean {
        return this.product.restricted;
    }

    get containerCSSClass(): string {
        return `${this.viewType} ${this.restricted ? 'container-disabled' : ''}`;
    }

    get orderCurrency(): Currency {
        return this._order?.currency ?? this._orderItem.product.currency;
    }

    get purchaseButtonTitle(): string {
        if (this.isNotSameCurrency) {
            return 'Currency differs from product in cart';
        }

        if (this.canPurchase) {
            return this._orderItem.id ? 'Update' : 'Add to cart';
        }


        return '';
    }


    get isNotSameCurrency(): boolean {
        return this._order.items.some(orderItem => {
            const result =  !isEqual(orderItem.product.currency, this._orderItem.product.currency);
            return result;
        });
    }

}
