import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import { Router } from '@angular/router';

import { AppSettings } from '../app.settings';
import { Response } from '../models/response.model';
import { AppDate } from '../models/date.model';
import { ProductCatalog } from '../models/product-catalog.model';
import { CacheModel } from '../models/cache.model';
import { Product } from '../models/product.model';
import {FilterGroup} from '../models/filter-group.model';
import {IDecisionPointData} from '../interfaces/decision-point.data';
import {IDecisionPointDelivery} from '../interfaces/decision-point-delivery';
import {findDetailedErrorMessage} from '../shared/helpers';
import {isEmptyArray, transformFilters} from '../shared/utils';
import {ProductCategory} from '../models/product-category.model';
import {Brand} from '../models/brand.model';
import {ProductResult} from '../interfaces/product.result';
import {API_URL} from '../constants/api-urls';

const BASE_URL = '/products';

@Injectable()
export class ApiProductService {
    public catalogCache: CacheModel;
    readonly apiURL: string;
    readonly  apiV2URL: string;

    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettings,
        protected toastr: ToastrService,
        protected router: Router
    ) {
        this.catalogCache = new CacheModel();
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
        this.apiV2URL = `${API_URL}${BASE_URL}`;
    }


    public getStatistics(orderWindowId: number): Observable<any> {
      const url = `${this.apiURL}/statistics/${orderWindowId}`;
      // const url = `${API_URL}${BASE_URL}/statistics/${orderWindowId}`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                  if (response.data) {
                      const result = response.data;

                      if (result.ow_upcoming) {
                          result.ow_upcoming.start_at = new AppDate(result.ow_upcoming.start_at);
                          result.ow_upcoming.end_at = new AppDate(result.ow_upcoming.end_at);
                      }
                      return result;
                  }

                  return null;
                }),
                catchError(this.handleError('ProductService::getStatistics', {}))
            );

    }

    public getProductCatalog(slugOrID: any = null): Observable<ProductCatalog> {
        if (this.catalogCache.has(slugOrID)) {
            return of(new ProductCatalog(this.catalogCache.get(slugOrID)));
        }
        const url = `${this.apiURL}/catalog/${slugOrID}`;
        // const url = `${API_URL}${BASE_URL}/catalog/${slugOrID}`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {

                    if (response.data) {
                        const result = response.data;

                        this.catalogCache.set(result.id, result);

                        return new ProductCatalog(result);
                    }

                    return null;
                }),
                catchError(this.handleError('ProductService::getCatalog', null))
            );
    }


    public getCatalogsIds(windowID: number = null, filter: Array<string> = null, paging: string = '18-0', sort: string = ''):
        Observable<{ids: number[], count: number}> {
        // ! Use Range for limit/offset calls - maintain state for pagination or infinite scroll
        const headers: HttpHeaders = new HttpHeaders({'Range': paging, 'X-Sort': sort});

        let ids: number[] = [];
        let count = 0;

        // const url = `${API_URL}${BASE_URL}/catalogs/${windowID}`;
        const url = `${this.apiURL}/catalogs/${windowID}`;
        return this.http.post<HttpResponse<Response>>(
            url,
            {filter: filter}, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {

                const response = new Response(httpResponse.body);

                if (Array.isArray(response.data)) {
                    count = parseInt(httpResponse.headers.get('X-Count'), 10);
                    ids = response.data;
                    return {ids, count};

                }
                return {ids, count};
            }),
            catchError(this.handleError('ProductService::getCachedCatalogs', {ids, count}))
        );
    }



    public getCatalogsByIds(ids: number[]): Observable<ProductCatalog[]> {

        if (ids.length > 0) {
          // const url = `${API_URL}${BASE_URL}/catalogs/for-ids`;
          const url = `${this.apiURL}/catalogs/for-ids`;
            return this.http.post<Response>(
                url, ids
            ).pipe(
                map(response => {
                    if (Array.isArray(response.data)) {
                        return response.data.map( i => new ProductCatalog(i));
                    }
                    return [];
                }),
                catchError(this.handleError('ProductService::getUncachedCatalogs', []))
            );
        } else {
            return of([]);
        }
    }

    // return list of products info
    public getProductsShortInfo(windowID: number, slugOrID: any = null, orderId: number = 0,  filter: Array<string> = null,
                                paging: string = '18-0', sort: string = '', show: string = '', wslrEntityId = 0)
            : Observable<ProductResult> {

        const  headersBody = {'Range': paging};
        if (sort) {
            headersBody['X-Sort'] = sort;
        }
        if (show) {
            headersBody['X-Show'] = show;
        }

        const headers: HttpHeaders = new HttpHeaders(headersBody);

        let url: string;
        if (slugOrID) {
            url = `${API_URL}${BASE_URL}/catalog/${slugOrID}/short-info`;
            // url = this.apiURL + '/catalog/' + slugOrID + '/short-info';
        } else {
            url = `${API_URL}${BASE_URL}/window/${windowID}/short-info`;
            // url = `${this.apiURL}/window/${windowID}/short-info`;
        }

        const body = {filter: filter};
        if (wslrEntityId) {
            body['entity_id'] = wslrEntityId;
        }
        if (orderId) {
            body['order_id'] = orderId;
        }


        return this.http.post<HttpResponse<Response>>(
            url, body, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                let count = 0;
                let products: Product[] = [];
                const response = new Response(httpResponse.body);
                if (Array.isArray(response.data)) {
                    count = parseInt(httpResponse.headers.get('X-Count'), 10);
                    products = response.data.map( i => new Product(i));
                }
                return {products, count};
            }),
            catchError(this.handleError('ProductService::getProductsShortInfo', {products: [], count: 0}))
        );
    }

    // return list of product ids
    public getProductsIds(windowID: number, slugOrID: any = null, filter: Array<string> = null,
                      paging: string = '18-0', sort: string = '', wslrEntityId = 0): Observable<{ids: number[], count: number}> {
        // ! Use Range for limit/offset calls - maintain state for pagination or infinite scroll
        const  headersBody = {'Range': paging};
        if (sort) {
            headersBody['X-Sort'] = sort;
        }
        const headers: HttpHeaders = new HttpHeaders(headersBody);

        let url: string;
        if (slugOrID) {
            // url = `${API_URL}${BASE_URL}/catalog/${slugOrID}/items` ;
            url = this.apiURL + '/catalog/' + slugOrID + '/items';
        } else {
            // url  = `${API_URL}${BASE_URL}/window/${windowID}/items`;
            url = this.apiURL + '/window/' + windowID + '/items';
        }

        const body = {filter: filter};
        if (wslrEntityId) {
            body['entity_id'] = wslrEntityId;
        }

        return this.http.post<HttpResponse<Response>>(
            url, body, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                let count = 0;
                let ids: number[] = [];

                const response = new Response(httpResponse.body);
                if (Array.isArray(response.data)) {
                    count = parseInt(httpResponse.headers.get('X-Count'), 10);
                    ids = response.data;

                }
                return {ids, count};
            }),
            catchError(this.handleError('ProductService::getCachedProducts', {ids: [], count: 0}))
        );
    }


    getProductsByIds( ids: number[]): Observable<Product[]> {

        if (!ids || ids.length === 0) {
            return of([]);
        }
        const url = `${API_URL}${BASE_URL}/for-ids`;
        // const url = `${this.apiURL}/for-ids`
        return this.http.post<Response>(url, ids).pipe(
            map(response => {
                if (Array.isArray(response.data)) {
                    return response.data.map( i => new Product(i));
                }
                return [];
            }),
            catchError(this.handleError('ProductService::getUncachedProducts', []))
        );
    }

    /**
     *
     * @param productIds - array of product ids
     * @param windowID
     */
    public refreshAggregateQuantities(productIds: number[], windowID: number): Observable<any> {

      const url = `${API_URL}${BASE_URL}/refresh-aggregate-quantities/${windowID}`;
        return this.http.post<Response>(url, productIds)
            .pipe(
                map( response => {
                    return response.data;
                }),
                catchError(this.handleError('ProductService::refreshAggregateQuantities', null))
            );
    }

    public toggleFavorite(product: Product): Observable<Product> {

        const cloneProduct = (p: Product) => {
            return new Product(p);
        }

        // const url = `${API_URL}${BASE_URL}/${product.id}`;
        const url = `${this.apiURL}/${product.id}`;
        return this.http.put<Response>(url, {favorite: !product.isFavorite})
            .pipe(
                map( response => {
                    if (response.data) {
                        const newProduct = cloneProduct(product);
                        newProduct.toggleFavorite();
                        return newProduct;
                    }
                    return product;
                }),
                catchError(this.handleError('ProductService::toggleFavorite', null))
            );
    }

    // POST: /api/products/favorites/{window_id:[0-9]+}/short-info
    public getFavoriteProducts(windowID: number, filter: Array<string> = null,
                          paging: string = '18-0', sort: string = '', show: string = '', wslrEntityId = 0):
            Observable<ProductResult> {
        // ! Use Range for limit/offset calls - maintain state for pagination or infinite scroll

        const  headersBody = {'Range': paging};
        if (sort) {
            headersBody['X-Sort'] = sort;
        }
        if (show) {
            headersBody['X-Show'] = show;
        }

        const headers: HttpHeaders = new HttpHeaders(headersBody);

        const url = this.apiV2URL + '/favorites/' + windowID + '/short-info';
        // const url = `${API_URL}${BASE_URL}/favorites/${windowID}/short-info`;

        const body = {filter: filter};
        if (wslrEntityId) {
            body['entity_id'] = wslrEntityId;
        }
        return this.http.post<HttpResponse<Response>>(
            url, body, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                let count = 0;
                let products: Product[] = [];

                const response = new Response(httpResponse.body);
                if (Array.isArray(response.data)) {
                    count = parseInt(httpResponse.headers.get('X-Count'), 10);
                    products = response.data.map( d => new Product(d));
                }
                return {products, count};
            }),
            catchError(this.handleError('ProductService::getFavoriteProducts', {products: [], count: 0}))
        );
    }


    public fetchFavoritesFilters(orderWindowId =  0, filters: FilterGroup[] = []): Observable<FilterGroup[]> {
        if (isEmptyArray(filters)) {
          // const url = `${API_URL}${BASE_URL}/favorites/${orderWindowId}/product-filters`;
          const url = `${this.apiURL}/favorites/${orderWindowId}/product-filters`;
            return this.http.get<Response>(url).pipe(
                map( response => {
                    if (Array.isArray(response.data)) {
                        return response.data.map( d => new FilterGroup(d));
                    }
                    return [];
                }),
                catchError(this.handleError('ProductService::fetchFavoritesFilters', []))
            );
        } else {
          // const url = `${API_URL}${BASE_URL}/favorites/${orderWindowId}/product-filters`;
          const url = `${this.apiURL}/favorites/${orderWindowId}/product-filters`;
            return this.http.post<Response>(url,
                {filter: transformFilters(filters)}).pipe(
                map( response => {
                    if (Array.isArray(response.data)) {
                        return response.data.map( d => new FilterGroup(d));
                    }
                    return [];
                }),
                catchError(this.handleError('ProductService::fetchFavoritesFilters', []))
            );
        }

    }

    public getDecisionPointData(term: string, dpID: string= '', productId: number = 0): Observable<IDecisionPointData[]> {

        const  mapResponseToDPData = (data): IDecisionPointData => {
            const result: IDecisionPointData = {
                id: data.id || dpID,
                wslr_count: +data.wslr_count,
                store_count: +data.store_count,
            }
            if (data.label) {
                result.label = data.label;
            }

            if (data.eligible_wslr_count) {
                result.eligible_wslr_count = +data.eligible_wslr_count;
            }

            return result;
        }

        // const url = `${API_URL}${BASE_URL}/get-distribution-data`;
        const url = `${this.apiURL}/get-distribution-data`;
        return this.http.post<Response>(url, {
            term: term,
            decision_point_id: dpID,
            product_id: productId
        }, {headers: this.xsrfTokenHeader}).pipe(
            map(response => {
                if (response.data) {
                    if (Array.isArray(response.data)) {
                        return response.data.map( d => mapResponseToDPData(d));
                    } else {
                        return [mapResponseToDPData(response.data)];
                    }
                }

                return [];
            }),
            catchError(this.handleError('ProductService::getDecisionPointData', []))
        );
    }

    public calculateDecisionPoints(dpID: string, productID: number, quantity: number): Observable<IDecisionPointDelivery[]> {
      // const url = `${API_URL}${BASE_URL}/calculate-distribution`;
      const url = `${this.apiURL}/calculate-distribution`;
        return this.http.post<Response>(url, {
            decision_point_id: dpID,
            product_id: productID,
            quantity: quantity
        }, {headers: this.xsrfTokenHeader}).pipe(
            map(response => {
                return response.data;
            }),
            catchError(this.handleError('ProductService::calculateDecisionPoints', []))
        );
    }

    public validateProduct(productId: number, windowId: number ): Observable<boolean> {
      //const url = `${API_URL}${BASE_URL}/${productId}/window/${windowId}/check`;
      const url = `${this.apiURL}/${productId}/window/${windowId}/check`;

        return this.http.get<Response>(url)
            .pipe(
                map( response => {
                    return response.data
                }),
                catchError(this.handleError('ProductService::validateProduct', false))
            );
    }

    public fetchProductCategories(): Observable<ProductCategory[]> {
      // const url = `${API_URL}${BASE_URL}/catalogs/od-categories`;
      const url = `${this.apiURL}/catalogs/od-categories`;

        return this.http.get<Response>(url).pipe(
            map( result => {
                if (isEmptyArray(result.data)) {
                    return []
                }

                return result.data.map( item => new ProductCatalog(item));
            }),
            catchError(this.handleError('ProductService::fetchProductCategories', []))
        );

    }

    public fetchProductBrands(): Observable<Brand[]> {
      // const url = `${API_URL}${BASE_URL}/brands`;
      const url = `${this.apiURL}/brands`;
        return this.http.get<Response>(url).pipe(
            map( response => {
                if (!isEmptyArray(response.data)) {
                    return response.data.map( d => new Brand(d));
                }
                return [];
            }),
            catchError(this.handleError('ProductService::fetchRecentProducts', []))
        );
    }

    public fetchRecentProducts(filter: Array<string> = null, paging: string = '18-0', sort: string = ''):
        Observable<{ids: number[], count: number}> {
        const headers: HttpHeaders = new HttpHeaders({'Range': paging, 'X-Sort': sort});

        const body = {filter: filter};
        // const url = `${API_URL}${BASE_URL}/recently-purchased`;
      const url = `${this.apiURL}/recently-purchased`;
        return this.http.post<HttpResponse<Response>>(
            url, body, {observe: 'response', headers: headers}
        ).pipe(
            map(httpResponse => {
                let count = 0;
                let ids: number[] = [];

                const response = new Response(httpResponse.body);
                if (!isEmptyArray(response.data)) {
                    count = parseInt(httpResponse.headers.get('X-Count'), 10);
                    ids = response.data;
                }
                return {ids, count};
            }),
            catchError(this.handleError('ProductService::fetchRecentProducts', {ids: [], count: 0}))
        );
    }

    public exportOrderedProducts(type: 'xlsx' | 'pdf',   windowID: number, slugOrID: any = null, filter: Array<string> = null,
                         sort: string = '', wslrEntityId = 0): Observable<boolean> {
        const  headersBody = {};
        if (sort) {
            headersBody['X-Sort'] = sort;
        }
        const headers: HttpHeaders = new HttpHeaders(headersBody);
        // const url = slugOrID ?
        //     `${API_URL}${BASE_URL}/catalog/${slugOrID}/short-info/download/${type}` :
        //     `${API_URL}${BASE_URL}/window/${windowID}/short-info/download/${type}`;

      const url = slugOrID ?
        `${this.apiURL}/catalog/${slugOrID}/short-info/download/${type}` :
        `${this.apiURL}/window/${windowID}/short-info/download/${type}`;

        const body = {filter: filter};
        if (wslrEntityId) {
            body['entity_id'] = wslrEntityId;
        }

        return this.http.post<Response>(
            url, body, {headers: headers}
        ).pipe(
            map(response => {
                return !!response.data
            }),
            catchError(this.handleError('ProductService::getCachedProducts', false))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }


    private get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }

}
