import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Transaction } from '../../../models/transaction.model';
import { TransactionState } from '../../../constants/transaction-state';
import {TransactionGroup} from '../../../models/transaction-group.model';

@Component({
    selector: 'app-transation-result-modal',
    templateUrl: './transaction-result-modal.component.html',
    styleUrls: ['./transaction-result-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TransactionResultModalComponent implements  OnInit {

    transactionGroup: TransactionGroup;

    constructor(private activeModal: NgbActiveModal,
                private ngbModal: NgbModal) {}

    ngOnInit(): void {
    }

    close(result = '') {
        this.activeModal.close(result);
    }



    displayCreditCardLabel(last_four): string {
        return `${last_four}`;
    }

    get isSuccessful(): boolean {
        return  this.transactionGroup.totals.state === TransactionState.SUCCESS;
    }

    startOver() {
        this.close('start_over');
    }
}

