import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AbstractControl, FormArray, FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators, ValidationErrors} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import omit from 'lodash/omit';

import {Country, CountryState} from '../../../models/country.model';
import {Address} from '../../../models/address.model';
import {ConfigService} from '../../../services/config.service';
import {AddressResult, AddressService} from '../../../services/address.service';
import {isEmptyArray} from '../../../shared/utils';
import {AuthService} from '../../../services/auth.service';
import {MultiTenantService} from '../../../services/multi-tenant.service';

// pattern="^\d{5}(?:[\-]\d{4})?$" required
const ZIP_PATTERN = /^\d{5}(?:[\-]\d{4})?$/;
const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * zip validator only for US
 */
function zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const countryCode = control.root.value['country'];
        if (!countryCode) {
            return null;
        } else if (countryCode === 'US') {
            if (!control.value) {
                return {required: 'required'};
            } else if (!ZIP_PATTERN.test(control.value)) {
                return {pattern: 'invalid'}
            }
        }
        return null;
    };
}

function emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const email = control.value;

        if (email) {
            if (!EMAIL_PATTERN.test(control.value)) {
                return {pattern: 'invalid'}
            }

            return null
        }

        return null;
    };
}

function maxLengthTrimmedValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null; // No value, no validation error
        }

        const trimmedValue = control.value.trim();
        if (trimmedValue.length > maxLength) {
            return { maxLengthTrimmed: { requiredLength: maxLength, actualLength: trimmedValue.length } };
        }

        return null; // Valid
    };
}

@Component({
    selector: 'app-add-address-modal',
    templateUrl: './address-modal.component.html',
    styleUrls: ['./address-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressModalComponent implements OnInit {
    selectedAddress: Address;
    isDeliveryAddress: boolean;

    states: { abbr: string, label: string }[] = [];
    inProgress = false;
    isNewAddress = true;

    suggestedAddresses: Address[] = [];
    selectedSuggestionUUID: string;
    isExistedAddress = false;
    numberOfAdditionalFields = 10;

    addressForm = new UntypedFormGroup({
        label: new UntypedFormControl('', [Validators.required, maxLengthTrimmedValidator(250)]),
        street_1: new UntypedFormControl('', [Validators.required,  maxLengthTrimmedValidator(250)]),
        street_2: new UntypedFormControl('', [maxLengthTrimmedValidator(250)]),
        city: new UntypedFormControl('', [Validators.required, maxLengthTrimmedValidator(250)]),
        state: new UntypedFormControl('',[ maxLengthTrimmedValidator(250)]),
        zip_code: new UntypedFormControl('', [zipCodeValidator(),  maxLengthTrimmedValidator(250)]),
        country: new UntypedFormControl(''),
        state_list: new UntypedFormControl(),
        phone: new UntypedFormControl('',[ maxLengthTrimmedValidator(25)]),
        client_address_id: new UntypedFormControl(),
        delivery_instructions: new UntypedFormControl(),
        contact_details: new FormArray([])
    });
    stateFieldTouch = false;

    constructor(private configService: ConfigService,
        private addressService: AddressService,
        private activeModal: NgbActiveModal,
        private authService: AuthService,
        private multiTenantService: MultiTenantService
    ) { }

    ngOnInit(): void {
        this.initAddressFields();
    }

    close(address?: Address): void {
        this.activeModal.close(address);
    }

    onIsNumberAndSymbolKey(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (![40, 41, 43, 45, 47].includes(charCode) && (charCode < 48 || charCode > 57)) {
            return false;
        }

        return true;
    }

    get label(): UntypedFormControl {
        return this.addressForm.get('label') as UntypedFormControl;
    }

    get street_1(): UntypedFormControl  {
        return this.addressForm.get('street_1') as UntypedFormControl;
    }

    get street_2(): UntypedFormControl  {
        return this.addressForm.get('street_2') as UntypedFormControl;
    }

    get city(): UntypedFormControl  {
        return this.addressForm.get('city') as UntypedFormControl;
    }

    get zip_code(): UntypedFormControl  {
        return this.addressForm.get('zip_code') as UntypedFormControl;
    }

    get delivery_instructions(): UntypedFormControl  {
        return this.addressForm.get('delivery_instructions') as UntypedFormControl;
    }

    get state(): UntypedFormControl  {
        return this.addressForm.get('state') as UntypedFormControl;
    }

    get state_list(): UntypedFormControl  {
        return this.addressForm.get('state_list') as UntypedFormControl;
    }

    get country(): UntypedFormControl  {
        return this.addressForm.get('country') as UntypedFormControl;
    }

    get phone(): UntypedFormControl  {
        return this.addressForm.get('phone') as UntypedFormControl;
    }

    get client_address_id(): UntypedFormControl  {
        return this.addressForm.get('client_address_id') as UntypedFormControl;
    }

    get contact_details(): FormArray  {
        return this.addressForm.get('contact_details') as FormArray;
    }

    validationClassName(control: AbstractControl): string {
        if (control.touched || control.dirty) {
            return control.invalid ? 'has-danger' : '';
        }
        return '';
    }

    invalidField(control: AbstractControl): boolean {
        return control.errors && (control.touched || control.dirty);
    }

    selectCountry(event: Event): void {
        if (event instanceof Country) {
            this.states = event.states;
            this.state_list.setValue('');
            this.state.setValue('');
            this.zip_code.setValue('');
            this.zip_code.markAsUntouched();
        }
    }

    selectState($event: Event | {}): void {
        this.stateFieldTouch = true;
        this.selectedAddress.state = $event['abbr'] || '';
        this.state.setValue(this.selectedAddress.state);
    }

    get isStateFieldValid(): boolean {
        if (this.addressForm.value['country'] === 'US') {
            return !!(this.state_list.value);
        } else {
            return true;
        }
    }

    get countries(): Country[] {
        return this.configService.countries;
    }

    initAddressFields(): void {
        const emptyMainContactDetails  = {
            contact: '',
            email: '',
            main: true,
        };

        const emptyContacts = [
            emptyMainContactDetails
        ];

        if (!this.selectedAddress) {
            // create a new contact
            this.selectedAddress = new Address({
                id: 0,
                address_type: 1,
                is_default: false,
                type: 'OTHER_ADDR',
                country_code: this.defaultUserCountryCode,
                contact_details: emptyContacts,
            });
        } else {
            if (isEmptyArray(this.selectedAddress.contact_details)) {
                this.selectedAddress.contact_details = emptyContacts;
            } else {
                const hasMainContacts = this.selectedAddress.contact_details.find( c => c.main === true);
                if (!hasMainContacts) {
                    this.selectedAddress.contact_details = [emptyMainContactDetails, ...this.selectedAddress.contact_details];
                }
            }
        }

        this.isNewAddress = !this.selectedAddress.id;
        this.label.setValue(this.selectedAddress.label);
        this.street_1.setValue(this.selectedAddress.street_1);
        this.street_2.setValue(this.selectedAddress.street_2);
        this.city.setValue(this.selectedAddress.city);
        this.country.setValue(this.selectedAddress.country_code);
        this.state.setValue(this.selectedAddress.state);
        this.state_list.setValue(this.selectedAddress.state);
        this.zip_code.setValue(this.selectedAddress.zip_code);
        this.phone.setValue(this.selectedAddress.phone);
        this.client_address_id.setValue(this.selectedAddress.client_address_id);
        this.delivery_instructions.setValue(this.selectedAddress.delivery_instructions);
        this.selectedAddress.contact_details.forEach((item) => {
            this.contact_details.push(
                new FormGroup({
                    contact: new UntypedFormControl(item.contact),
                    email: new UntypedFormControl(item.email, [emailValidator()]),
                    main: new UntypedFormControl(item.main)
                })
            )
        })

        this.states = this.getStatesByCountryCode(this.selectedAddress.country_code);
        this.stateFieldTouch = false;
    }

    onSaveAddress(): void {
        this.stateFieldTouch = true;
        if (this.addressForm.valid && this.isStateFieldValid) {
            this.suggestedAddresses = [];
            this.selectedSuggestionUUID = null;
            const addressId = this.selectedAddress.id;
            const type = this.selectedAddress.type;

            this.selectedAddress = new Address({...this.addressForm.value,
                is_default: this.selectedAddress.is_default,
                address_type: this.selectedAddress.address_type,
                wslr_ids: [...this.selectedAddress.wslr_ids]
            });

            if (addressId) {
                this.selectedAddress.id = addressId;
            }
            if (type) {
                this.selectedAddress.type = type;
            }

            if (this.states.length > 0) {
                this.selectedAddress.state = this.selectedAddress['state_list'] || '';
            } else {
                this.selectedAddress.state = this.selectedAddress.state  || '';
            }

            this.selectedAddress.contact_details = this.selectedAddress.contact_details.filter(c => {
                return c.contact.trim() !== '' && c.email.trim() !== ''; // do not save  if  contact or  email is empty
            })

            this.selectedAddress.country_code = this.addressForm.value['country'];

            this.inProgress = true;
            this.addressService.save(this.selectedAddress).subscribe((result: AddressResult) => {
                this.inProgress = false;
                if (!result) {
                    // error of validation or saving
                    return;
                }

                if (result.savedAddress) {
                    this.close(result.savedAddress);
                    return;
                }

                this.isExistedAddress = result.exists;
                this.suggestedAddresses = result.addresses || [];

                if (this.isDeliveryAddress && this.isExistedAddress && !isEmptyArray(this.suggestedAddresses)) {
                    // on product page - close dialog and return the address
                    this.close(this.suggestedAddresses[0]);
                }

                if (this.suggestedAddresses.length === 1) {
                    // autoselect suggested address
                    this.selectedSuggestionUUID = this.suggestedAddresses[0].uuid;
                }
            });
        } else {
            Object.entries(this.addressForm.controls).forEach((control: [string, any]) => {
                if (control[1] instanceof UntypedFormControl) {
                    control[1].markAsTouched();
                }
            });
        }
    }

    private getStatesByCountryCode(isoCountry: string): CountryState[] {
        const foundCountry = this.getCountryByCode(isoCountry);
        return foundCountry ? foundCountry.states : [];
    }

    getCountryByCode(countryCode: string): Country {
        return this.countries.find( c => c.isoCode === countryCode);
    }

    get hasSuggestions(): boolean {
        return Array.isArray(this.suggestedAddresses) && this.suggestedAddresses.length > 0;
    }

    get title(): string {
        if (this.hasSuggestions) {
            return  'Verify Address';
        }

        return this.isNewAddress ? 'Add New Address' : 'Edit Address';
    }

    onSelectedSuggestion(address: Address): void {
        this.selectedSuggestionUUID = address ? address.uuid : null;
    }

    onBackToEdit(): void {
        this.isExistedAddress = false;
        this.selectedSuggestionUUID = null;
        this.suggestedAddresses = [];
    }

    onSavedValidated(): void {
        const address = this.suggestedAddresses.find( a => a.uuid === this.selectedSuggestionUUID);
        if (!address) {
            return;
        }

        address.is_default = this.selectedAddress.is_default;
        if (this.selectedAddress.id) {
            address.id = this.selectedAddress.id;
        }
        if (this.selectedAddress.type) {
            address.type = this.selectedAddress.type;
        }

        if (this.selectedAddress?.address_type) {
            address.address_type = this.selectedAddress.address_type;
        }

        address.contact_details = this.selectedAddress.contact_details || [];

        if (this.selectedAddress.client_address_id) {
            address.client_address_id = this.selectedAddress.client_address_id;
        }

        if (this.selectedAddress.phone) {
            address.phone = this.selectedAddress.phone;
        }

        this.inProgress = true;
        this.addressService.save(address).subscribe( result => {
            this.inProgress = false;
            if (result && result.savedAddress) {
                this.close(result.savedAddress);
            }
        });
    }

    onAddContactDetail(): void {
        if (this.contact_details.controls.length < 10) {
            this.contact_details.push(
                new FormGroup({
                    contact: new UntypedFormControl(),
                    email: new UntypedFormControl('', [emailValidator()]),
                    main: new UntypedFormControl(false)
                })
            )
        }
    }

    onRemoveContactDetail(indexToRemove: number) {
        const contact: FormGroup  =  this.contact_details.controls[indexToRemove] as FormGroup;
        const isMain   =  contact.controls['main'].value === true;

        if (isMain) {
            // for main contract  just reset  input fields,do not remove them
            contact.controls['contact'].setValue('');
            contact.controls['email'].setValue('');
        } else {
            this.contact_details.removeAt(indexToRemove);
        }
        return false;
    }

    get isEmployee(): boolean {
        return  this.authService.isEmployee;
    }

    get existedAddressWarning(): string  {
        if (this.isEmployee) {
            if (isEmptyArray(this.suggestedAddresses)) {
                return 'This address already exists and cannot be added again.';
            }

            const address = this.suggestedAddresses[0];
            if (address.iShippingLocation) {
                return 'This address already exists. Please search for this Wholesaler in the Ship-To address dropdown with the Wholesaler name or number. This address can not be added to your address book.';
            }
            return 'This address already exists and can not be added to your address book.';
        }

        return 'This address already exists and cannot be added again.';
    }

    get projectName(): string {
        return this.multiTenantService.projectLabel;
    }

    get defaultUserCountryCode(): string {
        const userCountries = this.authService.userCountries;
        if (isEmptyArray(userCountries)) {
            return 'US';
        }

        return userCountries[0].alpha2 || 'US';
    }
}
