<div class="modal-md sap-modal">
    <div class="modal-header state">
        <h1 *ngIf="headerLabel" class="modal-title text-truncate full-width text-align-center">{{headerLabel}}</h1>
        <h1 *ngIf="!headerLabel" class="modal-title text-truncate full-width text-align-center" id="modal-label"> {{ canSwitchOrderType ? 'SELECT ORDER TYPE' : 'MANAGE ORDER SETTINGS' }} </h1>
        <button type="button" [disabled]="!canCloseDialog"
                (click)="close()" class="btn btn-icon close" aria-label="Close"><span class="fal fa-times" aria-hidden="true"></span></button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="canSwitchOrderType">
            <div class="btn-group btn-group-toggle sap-modal--order-type" role="group" aria-label="Order Type">
                <button type="button" class="btn btn-secondary sap-order--type" [ngClass]="isSapType === SapType.STANDARD ? 'active' : ''" (click)="isSapType = SapType.STANDARD">Standard Order</button>
                <button *ngIf="canAddDecisionPointOrder" type="button" class="btn btn-secondary sap-order--type" [ngClass]="isSapType === SapType.DECISION_POINT ? 'active' : ''" (click)="isSapType = SapType.DECISION_POINT">Decision Point Order</button>
                <button type="button" class="btn btn-secondary sap-order--type" [ngClass]="isSapType === SapType.WHOLESALER ? 'active' : ''" (click)="isSapType = SapType.WHOLESALER">Wholesaler Order</button>
            </div>
        </ng-container>
        <div *ngIf="isSapType === SapType.WHOLESALER; else sapContainer" class="sap-container--wholesaler">
            <ng-container *ngIf="canSwitchOrderType">
                <h2>Select Wholesaler</h2>
                <div class="input-group">
                    <ng-select
                            class="custom-select__wholesalers"
                            [items]="wholesalerSearchResults"
                            [(ngModel)]="wholesalerSelectionId"
                            bindValue="id"
                            placeholder="Search Wholesaler"
                            [searchable]="true"
                            [loading]="wholesalerSearchLoading"
                            [typeahead]="wholesalerSearchSubject"
                            (change)="selectWholesaler($event)"
                            [clearable]="false"
                            *ngIf="canSwitchOrderType">
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.label }}
                        </ng-template>
                        <ng-template ng-header-tmp>
                            Search Wholesalers
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span>WSLR {{ item.external_id }} - {{ item.label }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </ng-container>


            <div class="input-group wholesaler--order-name">
                <label for="order-name">
                    <span>Order Name</span>
                    &nbsp;<small>(Name Your Order For Easy Reference)</small>
                </label>
                <input type="text" name="order-name" id="order-name" placeholder="Order name" [(ngModel)]="wslrOrderName">
            </div>

            <div class="input-group wholesaler--tax-exempt">
                <label for="wslr-tax-exempt">Tax Exempt:
<!--                    <span class="color&#45;&#45;primary-blue uppercase"-->
<!--                          *ngIf="!isWSLRTaxExemptAllowed" placement="right" tooltipClass="tooltips" ngbTooltip="No certificates are on file, please contact each vendor with your certificates.">-->
<!--                                    &nbsp;<i class="fas fa-info-circle"></i>-->
<!--                                </span>-->
                    <small>&nbsp;(Exemptions will be applied for suppliers holding proper exemption certificates)</small>
                </label>
                <input class="checkbox-dark-gray tax-exempt" type="checkbox" [(ngModel)]="wslrTaxExempt" id="wslr-tax-exempt">
            </div>
            <button [disabled]= "!canCreateWSLROrder || inProgress" (click)="createWholesalerOrder()" type="button" class="btn btn-primary mt-3 mb-3">{{canSwitchOrderType ? 'Start Order' : 'Save'}}
                <ng-container *ngIf="inProgress">&nbsp;<i class="fal fa-sync rotate"></i></ng-container>
            </button>
        </div>

        <ng-template #sapContainer>
            <div *ngIf="isSapType === SapType.DECISION_POINT" class="col-12 mt-0 mb-3 dp-search">
                <ng-select
                        class="custom-select__decision-points"
                        [items]="dpSearchResults"
                        [(ngModel)]="dpSelectionId"
                        bindValue="id"
                        placeholder="Search Decision Points"
                        [searchable]="true"
                        [loading]="dpSearchLoading"
                        [typeahead]="dpSearchSubject"
                        (change)="selectDP($event)"
                        [clearable]="false"
                        *ngIf="canSwitchOrderType">
                    <ng-template ng-label-tmp let-item="item">
                        {{ dpSelection.label }}
                    </ng-template>
                    <ng-template ng-header-tmp>
                        Search Results
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span>{{ item.label }}</span><br />
                        {{ item.wslr_count }} Wholesalers, {{ item.store_count }} Stores
                    </ng-template>
                </ng-select>
            </div>


            <div class="container modal-with-scroll">
                <div class="row">

                    <div class='col-12' *ngIf='isPresetAddressesAllowed'>
                        <div class='input-group'>
                            <label><span>Select Addresses</span></label>
                            <app-order-list-address-list [addresses]="presetAddresses"
                                                         [disabled]="!canEditPresetAddresses"
                                                         (onAddressListChanged)="onAddressListChanged($event)">
                            </app-order-list-address-list>
<!--                            <app-preset-address-list-->
<!--                                [addresses]="presetAddresses"-->
<!--                                [disabled]="!canEditPresetAddresses"-->
<!--                                (onAddressListChanged)="onAddressListChanged($event)"-->
<!--                            ></app-preset-address-list>-->
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="input-group">
                            <app-payment-method-selector [useDefaultPayment]="true"
                                                         [order]="order"
                                                         [verifyingSAP]="verifyingSAP"
                                                         [updatingPaymentMethod]="updatingPaymentMethod"
                                                         (savedCard)="onSavedCard($event)"
                                                         (paymentChanged)="onPaymentChanged($event)"></app-payment-method-selector>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-12">
                        <div class="input-group">
                            <label for="companyBillingEntity"><span>Company Billing Entity</span>&nbsp;<small>(Enter Company Billing Entity Here)</small></label>
                            <input type="text" name="companyBillingEntity" id="companyBillingEntity"
                                   placeholder="Enter Company Billing Entity" [readonly]="true"
                                   [(ngModel)]="companyBillingEntity">
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-group">
                            <label for="vendorNumber"><span>Vendor Number</span>&nbsp;<small>(Enter Vendor Number Here)</small></label>
                            <input type="text" name="vendorNumber" id="vendorNumber"
                                   placeholder="Enter Vendor Number" [readonly]="true"
                                   [(ngModel)]="vendorNumber">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="input-group">
                            <label for="{{isSapType === SapType.DECISION_POINT ? 'dp-order-label' : 'order-label'}}">
                                <span>Order Name</span>
                                &nbsp;<small>(Name Your Order For Easy Reference)</small>
                            </label>

                            <input *ngIf="isSapType === SapType.DECISION_POINT" type="text" name="order-name" id="dp-order-label" [(ngModel)]="dpOrderName">
                            <input *ngIf="isSapType === SapType.STANDARD" type="text" name="order-name" id="order-label" [(ngModel)]="orderName">
                        </div>
                    </div>


                  <div class="col-12" *ngIf="!showInternalOrderCodes">
                    <div class="input-group">
                      <label><span>Order Code</span>&nbsp;&nbsp;<small>(SELECT ORDER TYPE)</small></label>
                      <div class="flex gap-10">
                        <label class="custom-control custom-radio">
                          <span class="font-4 font-weight-normal text-transformation-none">Internal Order</span>
                          <input value="{{  isInternalOrderSelected  }}" name="orderType" class="custom-control-input radio-dark-gray" type="radio" (change)="toggleInternalOrder()" [checked]="isInternalOrderSelected">
                          <span class="custom-control-indicator"></span>

                        </label>
                        <label class="custom-control custom-radio">
                          <span class="font-4 font-weight-normal  text-transformation-none">WBS Code</span>
                          <input value="{{  isWBSSelected }}" name="orderType" class="custom-control-input radio-dark-gray" type="radio" (change)="toggleWBS()" [checked]="isWBSSelected">
                          <span class="custom-control-indicator"></span>
                        </label>
                      </div>
                      <div>
                        <input type="text" name="internalOrder" id="internalOrder" *ngIf='!isWBSSelected'
                               placeholder="Enter Default Order Code" class="w-full"
                               [disabled]='!canChangeWBS || !isInternalOrderSelected'
                               (change)="internalOrderChanged()"
                               [(ngModel)]="internalOrder">

                        <input type="text" name="wbs" id="wbs" *ngIf="isWBSSelected"
                               placeholder="Enter Default Order Code" class="w-full"
                               (change)="wbsChanged()"
                               [disabled]="!canChangeWBS || !isWBSSelected"
                               [(ngModel)]="wbs">
                      </div>
                    </div>
                  </div>
                </div>



            </div>
            <div class="row mb-3 mt-3">
                <button (click)="onApplyPayment()"
                        [disabled]="!canApply || inProgress"
                        type="button"
                        class="btn btn-primary apply-sap">
                    <i *ngIf="inProgress" class="fal fa-sync rotate"></i>&nbsp;
                    Apply &amp; Begin Order
                </button>
            </div>


        </ng-template>
    </div>
</div>

