<div class='address--item--container'>
    <div class='select--container'>
        <ng-select
            #select
            class="searchable address--selector"
            [ngClass]="dropDownClasses"
            [items]="computedAddressList"
            bindValue="uniqueAddressId"
            placeholder="Select address"
            [searchable]="true"
            [loading]="addressesLoading"
            [typeahead]="addressesSubject"
            (change)="onSelect($event)"
            [clearable]="false"
            (close)="onCloseDropdown()"
            [disabled]="disabled"
            dropdownPosition="bottom"
            [(ngModel)]="unique_addr_id">
            <ng-template ng-label-tmp let-item="item">
                <span class="d-block font-weight-bold font-5">{{ formattedAddressLabel(item) }}</span>
                <span>{{ item.street_1 }}, {{ item.getCSZ() }}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div class="address-list-title font-weight-bold text-underline color-black font-6">
                    <ng-container *ngIf="item.isFirstItem && item.address_type === addressType.Corporate">
                        Corporate
                    </ng-container>
                    <ng-container *ngIf="item.isFirstItem && item.address_type === addressType.Personal">
                        Personal
                    </ng-container
                    >
                </div>
                <span class="font-weight-bold font-6">
                    <ng-container *ngIf="item.is_default">
                      <i class="fas fa-star color-gold"></i>&nbsp;
                    </ng-container>
                    {{ item.label }}</span><br/>
                <span class="font-6">{{ item.longAddress }}</span>
            </ng-template>
            <ng-template ng-footer-tmp>
                <a *ngIf="canAddAddresses" href="#" class="d-block text-align-center my-2 link font-5 link-underline"
                   (click)="addAddress()">
                    Add New Address&nbsp;<i class="fas fa-plus"></i>
                </a>
            </ng-template>
        </ng-select>
        <div class='remove-button--container ml-2' *ngIf='!disabled'>
            <button class='btn btn-icon with-border' (click)='removeAddress()' title="Remove the Address">
                <span class="fal fa-times" aria-hidden="true"></span>
            </button>
        </div>
    </div>
    <div class='add-button--container' *ngIf='showAddButton && !disabled'>
        <a class="link link-underline font-5 ml-2" (click)="addAnotherAddress()" title="Add Another Address"
           href="javascript:void(0)">Add Another Address <i class="fas fa-plus"></i></a>
    </div>
</div>


