import { Injectable } from '@angular/core';
import {mobileAndTabletCheck} from '../shared/utils';

const MOBILE_DEVICE_WIDTH = 576;
const TABLET_DEVICE_WIDTH = 1024;

@Injectable()
export class MobileCheckServiceService {

  private _mobile = false;
  private _tabletDevice = false;
  private _mobileDevice = false;
  private lg = window.matchMedia('(max-width: 1199px)');
  private md = window.matchMedia('(max-width: 991px)');
  private sm = window.matchMedia('(max-width: 767px)');
  private xs = window.matchMedia('(max-width: 575px)');

  constructor() { }

  check() {
    // this._mobile = mobileAndTabletCheck();
    // console.log('mobile agent', this._mobile);
    this._mobileDevice = window.innerWidth <= MOBILE_DEVICE_WIDTH;
    this._tabletDevice = !this._mobileDevice && window.innerWidth <= TABLET_DEVICE_WIDTH;
  }

  public get mobile(): boolean {
    // return this._mobile;
    return this._mobileDevice;
  }

  public get desktop(): boolean {
    return !this._mobile
  }

  public get mobileDevice(): boolean {
    return this._mobileDevice;
  }

  public get tabletDevice(): boolean {
    return this._tabletDevice;
  }

  public get isLgSize(): boolean {
    return this.lg.matches;
  }

  public get isMdSize(): boolean {
    return this.md.matches;
  }

  public get isSmSize(): boolean {
    return this.sm.matches;
  }

  public get isXsSize(): boolean {
    return this.xs.matches;
  }

}
