import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CreditCardModalComponent} from '../theme/dialogs/credit-card-modal/credit-card-modal.component';
import {CreditCardLabelModalComponent} from '../theme/dialogs/credit-card-label-modal/credit-card-label-modal.component';
import {CreditCard} from '../models/credit-card.model';
import {PaymentMethod} from '../models/payment-method.model';
import {RemovePaymentMethodModalComponent} from '../theme/dialogs/remove-payment-method-modal/remove-payment-method-modal.component';
import {CostCenter} from '../models/cost-center.model';
import {SapAccountComponent} from '../theme/dialogs/sap-account/sap-account.component';
import {Order} from '../models/order.model';
import {SapModalComponent} from '../theme/dialogs/sap-modal/sap-modal.component';
import {OrderSettingsModalComponent} from '../theme/dialogs/order-settings-modal/order-settings-modal.component';
import {AuthService} from './auth.service';
import {OrderItem} from '../models/order-item.model';
import {OrderItemModalComponent} from '../theme/dialogs/order-item-modal/order-item-modal.component';
import {AlertComponent} from '../theme/dialogs/alert/alert.component';
import {ProductCustomizationComponent} from '../theme/dialogs/product-customization/product-customization.component';
import { SelectSapComponent } from '../theme/dialogs/select-sap/select-sap.component';
import { PriceDetailsModalComponent } from '../theme/dialogs/price-details-modal/price-details-modal.component';
import { ShipToModalComponent } from '../theme/dialogs/ship-to/ship-to-modal.component';
import { OrderItemDelivery } from '../models/order-item-delivery.model';
import { TransactionDetailsModalComponent } from '../theme/dialogs/transaction-details-modal/transaction-details-modal.component';
import { TransactionResultModalComponent } from '../theme/dialogs/transaction-result-modal/transaction-result-modal.component';
import {CsvUploadResultComponent} from '../theme/dialogs/csv-upload-result/csv-upload-result.component';
import {TransactionGroup} from '../models/transaction-group.model';
import {RemoveOrderModalComponent} from '../theme/dialogs/remove-order-modal/remove-order-modal.component';
import {RemoveOrderItemModalComponent} from '../theme/dialogs/remove-order-item-modal/remove-order-item-modal.component';
import {RemoveItemModalComponent} from '../theme/dialogs/remove-item-modal/remove-item-modal.component';
import {Address} from '../models/address.model';
import {AddressModalComponent} from '../theme/dialogs/address-modal/address-modal.component';
import {DecisionPointModalComponent} from '../theme/dialogs/decision-point-modal/decision-point-modal.component';
import {IDecisionPointData} from '../interfaces/decision-point.data';
import {DecisionPointFaqComponent} from '../theme/dialogs/decision-point-faq/decision-point-faq.component';
import {BudgetDetailsModalComponent} from '../theme/dialogs/budget-details-modal/budget-details-modal.component';
import {CreditCardPaymentFailedComponent} from '../theme/dialogs/credit-card-payment-failed/credit-card-payment-failed.component';
import {IPaymentJsToken} from '../interfaces/payment-js.token';
import {TermsComponent} from '../theme/dialogs/terms/terms.component';
import {NewsModalComponent} from '../theme/dialogs/news-modal/news-modal.component';
import {LogoutModalComponent} from '../theme/dialogs/logout/logout-modal.component';
import {from, Observable, of} from 'rxjs';
import {MobileCheckServiceService} from './mobile-check-service.service';
import {ExportResultModalComponent} from '../theme/dialogs/export-result-modal/export-result-modal.component';
import {DefaultAddressModalComponent} from '../theme/dialogs/default-address-modal/default-address-modal.component';
import {InternalOrderModalComponent} from '../theme/dialogs/internal-order/internal-order.modal.component';
import {ConfigService} from './config.service';
import {ManageOrderListAddressesComponent} from '../theme/dialogs/manage-order-list-addresses-modal/manage-order-list-addresses-modal.component';
import {AddressUpdateResult} from '../interfaces/address-update-result';

export interface TermsResult  {accept?: boolean, logout?: boolean}

@Injectable()
export class ModalService {

    constructor(private ngbModal: NgbModal, private authService: AuthService,
                private mobileCheck: MobileCheckServiceService,
                private configService: ConfigService) {}

    public showCreditCardDialog(orderId: number = null,
                                creditCard: CreditCard = null,
                                token: IPaymentJsToken, save = false): Promise<CreditCard> {

        const modal = this.ngbModal.open(CreditCardModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.orderId = orderId;
        modal.componentInstance.card = creditCard;
        modal.componentInstance.token = token;
        modal.componentInstance.saveToPaymentMethods = save;
        return modal.result;
    }

    public showCreditCardLabelDialog(creditCard: CreditCard): Promise<CreditCard> {
        if (!creditCard) {
            return;
        }
        const modal = this.ngbModal.open(CreditCardLabelModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container'
            , windowClass: this.modalWindowClass});
        modal.componentInstance.creditCard = creditCard;
        return modal.result;
    }

    public showRemoveItemConfirmation(paymentMethod: PaymentMethod) {
        if (!paymentMethod) {
            return;
        }
        const modal = this.ngbModal.open(RemovePaymentMethodModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.item = paymentMethod;
        return modal.result;
    }

    public showSapAccountDialog(costCenter: CostCenter = null, isAdd: boolean = false): Promise<CostCenter> {
        const modal = this.ngbModal.open(SapAccountComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});

        modal.componentInstance.costCenter = costCenter || new CostCenter({});
        modal.componentInstance.operationType = isAdd ? 'Add' : 'Edit';
        return modal.result;
    }

    public showSAPModal(order: Order, canChangeOrderType = true, closeWithWarning = false, headerLabel  = '',
                        shouldUpdateShipments = false): Promise<Order> {
        const modal = this.ngbModal.open(SapModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass, keyboard: false});
        modal.componentInstance.order = order;
        modal.componentInstance.headerLabel = headerLabel;
        modal.componentInstance.canChangeOrderType = canChangeOrderType;
        modal.componentInstance.closeWithWarning = closeWithWarning;
        modal.componentInstance.shouldUpdateShipments = shouldUpdateShipments;
        return modal.result;
    }

    public showOrderSettingsModal(order: Order, headerLabel = '', shouldUpdateShipments = false): Promise<Order> {
        const modal = this.ngbModal.open(OrderSettingsModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass, keyboard: false});
        modal.componentInstance.order = order;
        modal.componentInstance.shouldUpdateShipments = shouldUpdateShipments;
        return modal.result.then(result => {
            if (result === 'openSapDialog') {
                return this.showSAPModal(order);
            }
            return result;
        });
    }

    public editOrderSettings(order: Order, options: {headerLabel?: string, shouldUpdateShipments?: boolean}
        = {headerLabel: '', shouldUpdateShipments: false}): Promise<Order> {
        if (!order) {
            return new Promise<Order>(resolve => resolve(null));
        }
        if (this.authService.canShowSAP && this.configService.showBillingInfo) {
            return this.showSAPModal(order, true, false, options.headerLabel, options.shouldUpdateShipments);
        } else {
            return this.showOrderSettingsModal(order, options.headerLabel, options.shouldUpdateShipments);
        }
    }


    public editOrderItemModal(order: Order, orderItem: OrderItem, checkForChanges?: Function,
                              showOrderSelector = false): Promise<OrderItem> {

        const beforeDismiss = () => {
            return checkForChanges ? checkForChanges(modal.componentInstance.orderItem) : true;
        }
        const modal = this.ngbModal.open(OrderItemModalComponent,
            {keyboard: true, backdrop: true, size: 'xl', beforeDismiss,
                backdropClass: 'backdrop--container',
                windowClass: 'modal-window--container'});
        modal.componentInstance.order = order;
        modal.componentInstance.orderItem = orderItem;
        modal.componentInstance.showOrderSelector = showOrderSelector;
        return modal.result.catch( () => {
            return null;
        });
    }

    public showReadonlyProductModal(order: Order, orderItem: OrderItem): Promise<OrderItem> {
        const modal = this.ngbModal.open(OrderItemModalComponent,
            {keyboard: true, backdrop: true, size: 'xl',
                backdropClass: 'backdrop--container',
                windowClass: this.modalWindowClass});
        modal.componentInstance.order = order;
        modal.componentInstance.orderItem = orderItem;
        modal.componentInstance.readonly = true;
        return modal.result.catch( () => {
            return null;
        });
    }

    public showAlert(settings: any = null, size = 'sm'): Promise<any> {
        const modal = this.ngbModal.open(AlertComponent, {backdrop: 'static', backdropClass: 'backdrop--container', size, keyboard: false});
        if (settings) {
            modal.componentInstance.settings = settings;
        }
        return modal.result;
    }

    public showProductCustomizationModal(data: any) {
        const modal = this.ngbModal.open(ProductCustomizationComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.customizationData = data;

        return modal.result;
    }

    public showSelectSAPModal(costCenter: CostCenter = null) {
        const modal = this.ngbModal.open(SelectSapComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.costCenter = costCenter;
        return modal.result;
    }

    public showPriceDetails( order: Order, orderItem: OrderItem = null) {

        const showBudgetDetails = () => {
            this.showBudgetDetails([orderItem]);
        }


        const modal = this.ngbModal.open(PriceDetailsModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            size: 'sm'});
        modal.componentInstance.order = order;
        modal.componentInstance.orderItem = orderItem;
        modal.componentInstance.showBudgetDetails = showBudgetDetails;
    }

    public showShipToModal(order: Order, orderItem: OrderItem,
                           orderItemDelivery: OrderItemDelivery,
                           waitForUpdate = true): Promise<any>  {
        const modal = this.ngbModal.open(ShipToModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            size: 'sm', windowClass: this.modalWindowClass});
        modal.componentInstance.order = order;
        modal.componentInstance.orderItem = orderItem;
        modal.componentInstance.orderItemDelivery = orderItemDelivery;
        modal.componentInstance.waitForUpdate = waitForUpdate;

        return modal.result;
    }

    public showInternalOrderModal(order: Order, orderItem: OrderItem,
                           orderItemDelivery: OrderItemDelivery,
                           waitForUpdate = true): Promise<any>  {
        const modal = this.ngbModal.open(InternalOrderModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            size: 'sm', windowClass: this.modalWindowClass});
        modal.componentInstance.order = order;
        modal.componentInstance.orderItem = orderItem;
        modal.componentInstance.orderItemDelivery = orderItemDelivery;
        modal.componentInstance.waitForUpdate = waitForUpdate;

        return modal.result;
    }


    public showTransactionDetailsModal(transactionGroup: TransactionGroup): Promise<TransactionGroup> {
        const modal = this.ngbModal.open(TransactionDetailsModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            size: 'lg', windowClass: this.modalWindowClass});
        modal.componentInstance.transactionGroup = transactionGroup;
        return modal.result;
    }

    public showTransactionResult(transactionGroup: TransactionGroup): Promise<string> {
        const modal = this.ngbModal.open(TransactionResultModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.transactionGroup = transactionGroup;
        return modal.result;
    }

    public showCsvUploadResult(uploadResult: any = {}): Promise<any> {
        const modal = this.ngbModal.open(CsvUploadResultComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.uploadResult = uploadResult;
        return modal.result;
    }

    public showRemoveOrderModal(order: Order): Promise<boolean> {
        const modal = this.ngbModal.open(RemoveOrderModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.order = order;
        return modal.result;
    }

    public showRemoveOrderItemModal(orderItem: OrderItem): Promise<OrderItem> {
        const modal = this.ngbModal.open(RemoveOrderItemModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.orderItem = orderItem;
        return modal.result;
    }

    public showRemoveItemModal(options: {label?: string, closeButton?: string, removeButton?: string} = {}): Promise<boolean> {
        const modal = this.ngbModal.open(RemoveItemModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.options = options;
        return modal.result;
    }

    public editAddress(address: Address, isDeliveryAddress = false): Promise<Address> {
        const modal = this.ngbModal.open(AddressModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.selectedAddress = address;
        modal.componentInstance.isDeliveryAddress = isDeliveryAddress;
        return modal.result;
    }

    public makeDefaultAddress(address: Address, wlrIdList: number[]): Promise<number[]> {
        const modal = this.ngbModal.open(DefaultAddressModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: `${this.modalWindowClass} small-modal`});
        modal.componentInstance.address = address;
        modal.componentInstance.wlsrIdList = wlrIdList;
        return modal.result;
    }

    public showDecisionPoint(orderDecisionPointData: IDecisionPointData, productDecisionPointData: IDecisionPointData): Promise<any> {
        const modal = this.ngbModal.open(DecisionPointModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.orderDecisionPointData = orderDecisionPointData;
        modal.componentInstance.productDecisionPointData = productDecisionPointData;
        return modal.result.then( dialogResult => {
            if (typeof dialogResult === 'boolean') {
                if (dialogResult === true) {
                    this.ngbModal.dismissAll();
                }
                return null;
            }

            return dialogResult;
        });
    }

    public showDecisionPointFaq(dpName: string): Promise<boolean> {
        const modal = this.ngbModal.open(DecisionPointFaqComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.dpName = dpName;
        return modal.result.then( redirectToFaqPage => {
            if (redirectToFaqPage) {
                this.ngbModal.dismissAll();
                return redirectToFaqPage;
            }
        });
    }


    public showBudgetDetails(orderItems: OrderItem[]): Promise<void> {
        const modal = this.ngbModal.open(BudgetDetailsModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.orderItems = orderItems;
        return modal.result;
    }

    public showCreditCardPaymentFailed(order: Order, transactionId: number): Promise<void> {
        const modal = this.ngbModal.open(CreditCardPaymentFailedComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        modal.componentInstance.order = order;
        modal.componentInstance.transactionId = transactionId;
        return modal.result;
    }


    public showTermsAndConditions(terms: string, withAcceptance = false): Promise<TermsResult> {
        if (!terms) {
            return new Promise<TermsResult>( resolve =>  resolve(null));
        }

        const modal = this.ngbModal.open(TermsComponent,
            {keyboard: false, backdrop: true, size: 'xl',
                backdropClass: 'backdrop--container', windowClass: this.modalWindowClass});
        modal.componentInstance.content = terms;
        modal.componentInstance.withAcceptance = withAcceptance;
        return modal.result;
    }

    public showLatestNews(news: string, size = 'xl'): Observable<void> {
        if (!news) {
            return of(null);
        }
        const modal = this.ngbModal.open(NewsModalComponent,
            {keyboard: true, backdrop: true, size,
                backdropClass: 'backdrop--container',
                windowClass: 'modal-window--container'});
        modal.componentInstance.content = news;
        return from(modal.result.catch( () => {
            return null;
        }));
    }


    public showLogout(): Promise<boolean> {
        const modal = this.ngbModal.open(LogoutModalComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass});
        return modal.result.catch( () => {
            return null;
        });
    }

    public showRecommendedProductExportResult(exportType: 'pdf' | 'xlsx'): Promise<void> {

        const modal = this.ngbModal.open(ExportResultModalComponent, {backdrop: 'static',
            backdropClass: 'backdrop--container',
            windowClass: `${this.modalWindowClass}  export--modal`});
        modal.componentInstance.exportType = exportType;
        return modal.result.catch( () => {
            return null;
        });
    }

    public showManageOrderListAddressesModal(orderType: string, shouldUpdateShipments = false): Promise<Order> {
        const modal = this.ngbModal.open(ManageOrderListAddressesComponent, {backdrop: 'static', backdropClass: 'backdrop--container',
            windowClass: this.modalWindowClass, });
        modal.componentInstance.orderType = orderType;
        modal.componentInstance.shouldUpdateShipments =  shouldUpdateShipments;
        return modal.result;
    }



    private get modalWindowClass(): string {
        if (this.mobileCheck.mobileDevice) {
            return 'mobile';
        } else if (this.mobileCheck.tabletDevice) {
            return 'tablet';
        }

        return '';

    }
}
