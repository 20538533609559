import {BaseModel} from './base.model';
import {AppDate} from './date.model';
import {EntityLocation} from './entity-location.model';
import {ContactDetails} from '../interfaces/contact-details';
import {generateUUID, isEmptyArray} from '../shared/utils';
import {AddressType} from '../enums/address-type';

export class Address extends BaseModel {
    public id: number;
    public active: boolean;
    public user_id: number;
    public type: string;
    public label: string;
    public street_1: string;
    public street_2: string;
    public city: string;
    public state: string;
    public zip_code: string;
    public country_code: string;
    public residential: boolean;
    public is_default: boolean;
    public editable: boolean;
    public created_at: AppDate;
    public updated_at: AppDate;
    public entity_id: number;
    public ord: number;
    public searchKey: string;
    public phone?: number;
    public client_address_id?: string;
    public delivery_instructions?: string;
    public contact_details?: Array<ContactDetails>;

    public is_valid: boolean;
    public uuid: string;

    public isWSLR: boolean;
    public wslr_ids: number[];
    public address_type: AddressType;

    public isFirstItem: boolean;

    static fromEntityLocation(location: EntityLocation): Address {
        if (!location) {
            return null;
        }
        const address = new Address(location);
        address.type = 'SHIPPING_LOCATION';
        address.is_valid = true;
        address.address_type = 2;
        return address;
    }
    constructor(data) {
        if (data && !data.hasOwnProperty('editable')) {
            data.editable = true;
        }

        super(data, {
            num: ['id', 'user_id', 'ord'],
            bool: ['residential', 'is_default', 'editable', 'is_valid'],
            date: ['created_at', 'updated_at']
        });

        const keys = [this.label, this.street_1, this.city, this.zip_code];
        this.searchKey = keys.join(' ');

        if (data) {
            this.is_default = !!data.is_default;
            this.is_valid = !!data.is_valid;
        }

        this.uuid = (data && data.uuid) ? data.uuid : generateUUID();

        if (data?.isWSLR) {
            this.isWSLR = data.isWSLR; // computed after search

        } else {
            this.isWSLR = false;
        }

        if (!this.wslr_ids) {
            this.wslr_ids = data?.wslr_ids || [];
        }

        if (this.label?.startsWith('WSLR')) {
            const pos = this.label.indexOf('-');
            if (pos !== -1) {
                this.label = this.label.substring(pos + 1);
            }
        }

        if (!this.contact_details) {
            this.contact_details = data.contact_details;
        }

        this.isFirstItem = data.isFirstItem || false;


        if (this.address_type === AddressType.Corporate) {
            this.is_valid = true;
        }
    }

    public getCSZ() {
        let result = this.city;
        if (this.state) {
            result += ', ' + this.state;
        }
        if (this.zip_code) {
            result += ', ' + this.zip_code;
        }
        return result;
    }

    public get longAddress(): string {
        return `${this.street_1}, ${this.getCSZ()}`;
    }

    public get isInternational(): boolean {
        return this.country_code && this.country_code.toLowerCase() !== 'us';
    }

    public isTheSame(address: Address): boolean {
        return this.street_1 === address.street_1 &&
            this.city === address.city &&
            this.zip_code === address.zip_code &&
            this.state === address.state &&
            this.country_code === address.country_code;
    }

    public get iShippingLocation(): boolean {
        return this.type === 'SHIPPING_LOCATION';
    }

    public get wslrIds(): number[] {
        if (!isEmptyArray(this.wslr_ids)) {
            return this.wslr_ids;
        }

        return [this.entity_id || 0];
    }

    public get isPersonal(): boolean {
        return this.address_type === AddressType.Personal;
    }

    public get  isCorporate(): boolean {
        return this.address_type === AddressType.Corporate;
    }

    public get  uniqueAddressId(): string {
        return `${this.id}-${this.address_type}`;
    }


    public get isDisabled(): boolean {
        return !this.isActive;
    }

    public get isActive(): boolean {
        return this.active === true;
    }


    public get isDefault(): boolean {
        return this.isActive && this.is_default === true;
    }

    public get mainContact(): string {
        return this.contact_details?.find( c  => c.main === true)?.contact || '';
    }

    public get mainOrFirstContactName(): string  {
        if (isEmptyArray(this.contact_details)) {
            return '';
        }

        const contact = this.contact_details?.find(c => c.main);
        return contact?.contact || this.contact_details[0].contact || ''; // return  main contact or  first one
    }
}
