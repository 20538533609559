import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'primeng/carousel';

// Theme Components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './components/back-top/back-top.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { CartSelectComponent } from './components/cart-select/select.component';

import { PipesModule as CorePipesModule } from '../pipes/pipes.module';
import { SapModalComponent } from './dialogs/sap-modal/sap-modal.component';
import { ProductPriceComponent } from './components/product-price/product-price.component';
import { OrderItemVariationsComponent } from './components/order-item-variations/order-item-variations.component';
import { ProductOrderItemComponent } from './components/product-order-item/product-order-item.component';
import { PriceTiersComponent } from '../modules/product/price-tiers/price-tiers.component';
import { ProductOrderItemHeaderComponent } from './components/product-order-item/product-order-item-header/product-order-item-header.component';
import { ProductOrderItemPriceComponent } from './components/product-order-item/product-order-item-header/product-order-item-price/product-order-item-price.component';
import { ProductOrderItemInStockComponent } from './components/product-order-item/product-order-item-header/product-order-item-in-stock/product-order-item-in-stock.component';
import { OrderSettingsModalComponent } from './dialogs/order-settings-modal/order-settings-modal.component';
import { ProductOrderItemMinMaxComponent } from './components/product-order-item/product-order-item-header/product-order-item-min-max/product-order-item-min-max.component';
import { ProductOrderItemDeliveryComponent } from './components/product-order-item/product-order-item-delivery/product-order-item-delivery-component';
import { ShipToAddressComponent } from '../modules/product/ship-to-address/ship-to-address.component';
import { ShippingLabelComponent } from '../modules/product/shipping-label/shipping-label.component';
import { DirectivesModule } from '../directives/directives.module';
import { HeaderCalloutComponent } from './components/header/header-callout/header-callout.component';
import { MobxAngularModule } from 'mobx-angular';
import { OrderItemModalComponent } from './dialogs/order-item-modal/order-item-modal.component';
import { SapAccountComponent } from './dialogs/sap-account/sap-account.component';
import { CreditCardModalComponent } from './dialogs/credit-card-modal/credit-card-modal.component';
import { SapInfoComponent } from './components/sap-info/sap-info.component';
import { CreditCardLabelModalComponent } from './dialogs/credit-card-label-modal/credit-card-label-modal.component';
import { PaymentMethodSelectorComponent } from './components/payment-method-selector/payment-method-selector.component';
import {RemovePaymentMethodModalComponent} from './dialogs/remove-payment-method-modal/remove-payment-method-modal.component';
import {AlertComponent} from './dialogs/alert/alert.component';
import { TrackingComponent } from '../modules/notifications/tracking/tracking.component';
import {ProductCustomizationComponent} from './dialogs/product-customization/product-customization.component';
import { CustomizationInfoComponent } from './components/customization-info/customization-info.component';
import { ProgramsGridComponent } from './components/programs-grid/programs-grid.component';
import { FilterGroupComponent } from '../modules/products/filter/filter-group/filter-group.component';
import { FilterSelectedGroupComponent } from '../modules/products/filter/filter-selected-group/filter-selected-group.component';
import { ScrollContainerComponent } from './components/scroll-container/scroll-container.component';
import { VendorModalComponent } from './dialogs/vendor-modal/vendor-modal.component';
import { SelectSapComponent } from './dialogs/select-sap/select-sap.component';
import { SapSelectorComponent } from './components/sap-selector/sap-selector.component';
import { PriceDetailsModalComponent } from './dialogs/price-details-modal/price-details-modal.component';
import { ShipToModalComponent } from './dialogs/ship-to/ship-to-modal.component';
import { TransactionDetailsModalComponent } from './dialogs/transaction-details-modal/transaction-details-modal.component';
import { TransactionResultModalComponent } from './dialogs/transaction-result-modal/transaction-result-modal.component';
import {CsvUploadResultComponent} from './dialogs/csv-upload-result/csv-upload-result.component';
import { FavoriteToggleComponent } from './components/product-order-item/favorite-toggle/favorite-toggle.component';
import {SelectOrderWindowComponent} from './components/select-order-window/select-order-window.component';
import {ModalComponent} from '../components/modal.component';
import {SearchComponent} from '../components/search/search.component';
import {GlobalSearchContainerComponent} from '../components/global-search-container/global-search-container.component';
import {ProductGalleryComponent} from '../modules/product/product-gallery/product-gallery.component';
import {NgxGalleryModule} from '../ngx-gallery';
import {RemoveOrderModalComponent} from './dialogs/remove-order-modal/remove-order-modal.component';
import {RemoveOrderItemModalComponent} from './dialogs/remove-order-item-modal/remove-order-item-modal.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { RemoveItemModalComponent } from './dialogs/remove-item-modal/remove-item-modal.component';
import { AddressModalComponent } from './dialogs/address-modal/address-modal.component';
import {DecisionPointModalComponent} from './dialogs/decision-point-modal/decision-point-modal.component';
import {DecisionPointFaqComponent} from './dialogs/decision-point-faq/decision-point-faq.component';
import {BudgetDetailsModalComponent} from './dialogs/budget-details-modal/budget-details-modal.component';
import { ProductGridItemComponent } from './components/product-grid-item/product-grid-item.component';
import {CreditCardPaymentFailedComponent} from './dialogs/credit-card-payment-failed/credit-card-payment-failed.component';
import { DeliveryTrackingComponent } from './components/product-order-item/product-order-item-delivery/delivery-tracking/delivery-tracking.component';
import {TermsComponent} from './dialogs/terms/terms.component';
import {NewsModalComponent} from './dialogs/news-modal/news-modal.component';
import {LogoutModalComponent} from './dialogs/logout/logout-modal.component';
import { HeaderNavigationComponent } from './components/header/header-navigation/header-navigation.component';
import { MobileNavigationMenuComponent } from './components/header/mobile-navigation-menu/mobile-navigation-menu.component';
import {MobileCartMenuComponent} from './components/header/mobile-cart-menu/mobile-cart-menu.component';
import {SortComponent} from './components/sort/sort.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import {ProductsGridComponent} from './components/grid/grid.component';
import {ProductDetailsComponent} from '../modules/product/product-details/details.component';
import { InfiniteProgressComponent } from './components/infinite-progress/infinite-progress.component';
import { HeaderSearchComponent } from './components/header/header-search/header-search.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import {CarouselItemComponent} from './components/carousel-item/carousel-item.component';
import { BriefProductInfoComponent } from './components/brief-product-info/brief-product-info.component';
import { BriefOrderHistoryInfoComponent } from './components/brief-order-history-info/brief-order-history-info.component';
import { ImageContainerComponent } from './components/image-container/image-container.component';
import { ProductRecommendedQuantityComponent } from './components/product-order-item/product-order-item-header/product-recommended-quantity/product-recommended-quantity.component';
import { ProductOrdersDetailsComponent } from './components/product-order-item/product-orders-details/product-orders-details.component';
import { ExportResultModalComponent } from './dialogs/export-result-modal/export-result-modal.component';
import {ShowItemComponent} from './components/show-item/show-item.component';
import { ProductOrderDetailsTableComponent } from './components/product-order-item/product-orders-details/product-order-details-table/product-order-details-table.component';
import {DefaultAddressModalComponent} from './dialogs/default-address-modal/default-address-modal.component';
import { OrderedProductsExportComponent } from './components/ordered-products-export/ordered-products-export.component';
import { CurrencyPriceComponent } from './components/currency-price/currency-price.component';
import { ClearableInputComponent } from './components/clearable-input/clearable-input.component';
import {InternalOrderModalComponent} from './dialogs/internal-order/internal-order.modal.component';
import { ProductItemNumberComponent } from './components/product-item-number/product-item-number.component';
import { UserGuideComponent } from './components/header/user-guide/user-guide.component';
import { PresetAddressListComponent } from './dialogs/sap-modal/preset-address-list/preset-address-list.component';
import {
    OnDemandMinMaxComponent
} from './components/product-order-item/product-order-item-header/on-demand-min-max/on-demand-min-max.component';
import {
    OnDemandSkuAvailableQtyComponent
} from './components/product-order-item/product-order-item-header/on-demand-sku-available-qty/on-demand-sku-available-qty.component';
import { ProductsListComponent } from './components/list/list.component';
import { ProductListItemComponent } from './components/list/product-list-item/product-list-item.component';
import { ProductOrderListItemDeliveryComponent } from './components/product-order-item/product-order-list-item-delivery/product-order-list-item-delivery-component';
import { ProductImageCellComponent } from './components/list/product-image-cell/product-image-cell.component';
import { ProductDetailsCellComponent } from './components/list/product-details-cell/product-details-cell.component';
import { ProductSubtotalCellComponent } from './components/list/product-subtotal-cell/product-subtotal-cell.component';
import {RowFilterGroupComponent} from '../modules/products/row-filter/filter-group/row-filter-group.component';
import {ManageOrderListAddressesComponent} from './dialogs/manage-order-list-addresses-modal/manage-order-list-addresses-modal.component';
import {OrderListAddressListComponent} from './dialogs/manage-order-list-addresses-modal/address-list/order-list-address-list.component';
import {
    OrderListAddressItemComponent
} from './dialogs/manage-order-list-addresses-modal/address-item/order-list-address-item.component';
import { ProductListItemInputComponent } from './components/list/product-list-item-input/product-list-item-input.component';
import { ToggleListButtonComponent } from './components/list/toggle-list-button/toggle-list-button.component';
import {InternalOrderCodeComponent} from './components/internal-order-code/internal-order-code.component';
import {PresetAddressItemComponent} from './dialogs/sap-modal/preset-address-item/preset-address-item.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        CorePipesModule,
        MobxAngularModule,
        CorePipesModule,
        DirectivesModule,
        NgxGalleryModule,
        CarouselModule
    ],
    declarations: [
        HeaderComponent,
        HeaderCalloutComponent,
        FooterComponent,
        SidebarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        BreadcrumbComponent,
        BackTopComponent,
        ApplicationsComponent,
        UserMenuComponent,
        CartSelectComponent,
        SapModalComponent,
        SapAccountComponent,
        CreditCardModalComponent,
        CreditCardPaymentFailedComponent,
        DecisionPointModalComponent,
        DecisionPointFaqComponent,
        ProductPriceComponent,
        OrderItemVariationsComponent,
        OrderSettingsModalComponent,
        OrderItemVariationsComponent,
        ProductOrderItemComponent,
        PriceTiersComponent,
        ProductOrderItemHeaderComponent,
        ProductOrderItemPriceComponent,
        ProductOrderItemMinMaxComponent,
        ProductOrderItemInStockComponent,
        ProductOrderItemDeliveryComponent,
        ProductOrderListItemDeliveryComponent,
        ShipToAddressComponent,
        ShippingLabelComponent,
        OrderItemModalComponent,
        SapInfoComponent,
        CreditCardLabelModalComponent,
        PaymentMethodSelectorComponent,
        RemovePaymentMethodModalComponent,
        AlertComponent,
        TrackingComponent,
        ProductCustomizationComponent,
        CustomizationInfoComponent,
        ProgramsGridComponent,
        FilterGroupComponent,
        RowFilterGroupComponent,
        FilterSelectedGroupComponent,
        VendorModalComponent,
        ScrollContainerComponent,
        SelectSapComponent,
        SapSelectorComponent,
        PriceDetailsModalComponent,
        ShipToModalComponent,
        TransactionDetailsModalComponent,
        TransactionResultModalComponent,
        CsvUploadResultComponent,
        FavoriteToggleComponent,
        SelectOrderWindowComponent,
        ModalComponent,
        SearchComponent,
        GlobalSearchContainerComponent,
        ProductGalleryComponent,
        RemoveOrderModalComponent,
        RemoveOrderItemModalComponent,
        OrderSummaryComponent,
        RemoveItemModalComponent,
        AddressModalComponent,
        DefaultAddressModalComponent,
        BudgetDetailsModalComponent,
        ProductGridItemComponent,
        DeliveryTrackingComponent,
        TermsComponent,
        NewsModalComponent,
        LogoutModalComponent,
        HeaderNavigationComponent,
        MobileNavigationMenuComponent,
        MobileCartMenuComponent,
        SortComponent,
        OrderInfoComponent,
        ProductsGridComponent,
        ProductDetailsComponent,
        InfiniteProgressComponent,
        HeaderSearchComponent,
        CarouselComponent,
        CarouselItemComponent,
        BriefProductInfoComponent,
        BriefOrderHistoryInfoComponent,
        ImageContainerComponent,
        ProductRecommendedQuantityComponent,
        ProductOrdersDetailsComponent,
        ExportResultModalComponent,
        ShowItemComponent,
        ProductOrderDetailsTableComponent,
        OrderedProductsExportComponent,
        CurrencyPriceComponent,
        ClearableInputComponent,
        InternalOrderModalComponent,
        ProductItemNumberComponent,
        UserGuideComponent,
        PresetAddressListComponent,
        PresetAddressItemComponent,
        OnDemandMinMaxComponent,
        OnDemandSkuAvailableQtyComponent,
        ProductsListComponent,
        ProductListItemComponent,
        ProductImageCellComponent,
        ProductDetailsCellComponent,
        ProductSubtotalCellComponent,
        ManageOrderListAddressesComponent,
        OrderListAddressListComponent,
        OrderListAddressItemComponent,
        ProductListItemInputComponent,
        ToggleListButtonComponent,
        InternalOrderCodeComponent
    ],
    providers: [],
    exports: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        BreadcrumbComponent,
        BackTopComponent,
        ApplicationsComponent,
        UserMenuComponent,
        SapModalComponent,
        SapAccountComponent,
        CreditCardModalComponent,
        DecisionPointModalComponent,
        ProductPriceComponent,
        OrderItemVariationsComponent,
        ProductOrderItemComponent,
        PriceTiersComponent,
        ShipToAddressComponent,
        ShippingLabelComponent,
        OrderItemModalComponent,
        ProductOrderItemPriceComponent,
        ProductOrderItemMinMaxComponent,
        SapInfoComponent,
        CreditCardLabelModalComponent,
        PaymentMethodSelectorComponent,
        AlertComponent,
        TrackingComponent,
        ProductCustomizationComponent,
        CustomizationInfoComponent,
        ProgramsGridComponent,
        FilterGroupComponent,
        FilterSelectedGroupComponent,
        ScrollContainerComponent,
        VendorModalComponent,
        SapSelectorComponent,
        PriceDetailsModalComponent,
        ShipToModalComponent,
        TransactionDetailsModalComponent,
        TransactionResultModalComponent,
        CsvUploadResultComponent,
        FavoriteToggleComponent,
        SelectOrderWindowComponent,
        CartSelectComponent,
        ModalComponent,
        SearchComponent,
        GlobalSearchContainerComponent,
        ProductGalleryComponent,
        RemoveOrderModalComponent,
        RemoveOrderItemModalComponent,
        OrderSummaryComponent,
        ProductGridItemComponent,
        TermsComponent,
        NewsModalComponent,
        LogoutModalComponent,
        SortComponent,
        OrderInfoComponent,
        ProductsGridComponent,
        ProductDetailsComponent,
        InfiniteProgressComponent,
        CarouselComponent,
        BriefProductInfoComponent,
        BriefOrderHistoryInfoComponent,
        ImageContainerComponent,
        ShowItemComponent,
        OrderedProductsExportComponent,
        CurrencyPriceComponent,
        ClearableInputComponent,
        InternalOrderModalComponent,
        ProductItemNumberComponent,
        UserGuideComponent,
        OnDemandMinMaxComponent,
        OnDemandSkuAvailableQtyComponent,
        ProductsListComponent,
        ProductListItemComponent,
        RowFilterGroupComponent,
        ManageOrderListAddressesComponent,
        OrderListAddressListComponent,
        OrderListAddressItemComponent,
        ToggleListButtonComponent,
        PresetAddressListComponent,
        PresetAddressItemComponent,
    ],
})
export class ThemeModule {
}
