import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {switchMap} from 'rxjs/operators';
import {from, of} from 'rxjs';

import {OrderService} from '../../../services/order.service';
import {Order} from '../../../models/order.model';
import {PaymentService} from '../../../services/payments.service';
import {CreditCard} from '../../../models/credit-card.model';
import {CreditCardModalComponent} from '../credit-card-modal/credit-card-modal.component';
import {TransactionGroup} from '../../../models/transaction-group.model';
import {TransactionState, transactionStateDescription, transactionStateToClass} from '../../../constants/transaction-state';
import {isEmptyArray} from '../../../shared/utils';
import {MobileCheckServiceService} from '../../../services/mobile-check-service.service';

@Component({
    selector: 'app-transaction-details-modal',
    templateUrl: './transaction-details-modal.component.html',
    styleUrls: ['./transaction-details-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TransactionDetailsModalComponent implements  OnInit {

    transactionGroup: TransactionGroup;

    order: Order;
    loading = false;
    charging = false;
    creditCards: CreditCard[] = [];
    selectedCreditCard: CreditCard;

    constructor(private orderService: OrderService, private paymentService: PaymentService,
                private activeModal: NgbActiveModal,
                private ngbModal: NgbModal, private toast: ToastrService, private mobileCheck: MobileCheckServiceService) {}

    ngOnInit(): void {
        this.filterCreditCards();
        this.loading = true;
        this.orderService.getOrder(this.transactionGroup.totals.order_id).subscribe( order => {
            if (order) {
                this.order = order;
                this.transactionGroup.setOrder(order);
            }
            this.loading = false;
        });

    }

    close(transaction?: TransactionGroup) {
        this.activeModal.close(transaction);
    }

    addCreditCard() {

        this.paymentService.getPaymentJsToken(null).pipe(
            switchMap( result => {
                if (!result) {
                    this.toast.error('Cannot add a new credit card. Please contact the support.');
                    return of(null);
                }

                const modal = this.ngbModal.open(CreditCardModalComponent, {backdrop: 'static'});
                // modal.componentInstance.orderId = this.order.id;
                modal.componentInstance.saveToPaymentMethods = true;
                modal.componentInstance.card = result.creditCard;
                modal.componentInstance.token = result.token;

                return from(modal.result);
            })
        ).subscribe( result => {
            if (result) {
                this.toast.success('The credit card has been added to payment methods');
                this.filterCreditCards();
                this.selectedCreditCard = result;
            }
        })

        return false;

    }


    updateCreditCard(creditCard: CreditCard) {
        this.selectedCreditCard = creditCard;
    }

    chargeCreditCard() {
        if (this.charging) {
            return;
        }

        this.charging = true;

        const transactionItem = this.transactionGroup.transactions.find( t => t.hasFailed);

        this.paymentService.fixTransaction(this.transactionGroup, transactionItem, this.order, this.selectedCreditCard).subscribe(
            retTransaction => {
                this.charging = false;
                this.close(retTransaction);
            }
        );
    }

    get canCharge(): boolean {
        return !!this.selectedCreditCard && !this.charging;
    }

    displayCreditCardLabel(creditCard): string {
        if (!creditCard || !creditCard.label) {
           return '';
        }

        return `${creditCard.label} - ${creditCard.last_four}`;
    }

    get hasFailedTransaction(): boolean {
        return this.transactionGroup.totals.hasFailed;
    }

    get mobileDevice(): boolean {
        return this.mobileCheck.mobileDevice;
    }

    transactionStateClass(state: TransactionState): string {
        return transactionStateToClass(state);
    }

    transactionStateDescription(state: TransactionState): string {
        return transactionStateDescription(state);
    }

    private filterCreditCards() {
        if (!isEmptyArray(this.paymentService.creditCards)) {
            this.creditCards = this.paymentService.creditCards;
        } else {
            this.creditCards = [];
        }
    }
}

