import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {AddressService} from './address.service';
import {BudgetService} from './budget.service';
import {Order} from '../models/order.model';
import {Product} from '../models/product.model';
import {OrderItem} from '../models/order-item.model';
import {Address} from '../models/address.model';
import {computeAvailableAddresses} from '../shared/helpers';
import {isEmptyArray} from '../shared/utils';

@Injectable()
export class HelperService {
    constructor(
                private authService: AuthService,
                private addressService: AddressService,
                private budgetService: BudgetService,
                ) {
    }

    public canOrder(order: Order): boolean {
        return  this.authService.canEditOrder(order);
    }

    public setupOrderItem(order: Order, product: Product): OrderItem {

        if (!order || !product) {
            return null;
        }

        const userRole = {canOrder: true, isEmployee: this.authService.isEmployee};
        const presetAddresses =   order.presetAddresses;
        const orderItem = order.getItemForProduct(product, {
            defaultDelivery: null,
            userRole: userRole,
            presetAddresses: presetAddresses,
        });


        if (this.canOrder(order)) {

            // Select first address
            if (orderItem.deliveries.length > 0 && !orderItem.deliveries[0].street_1) {
                if (order.isWSLR) {
                    // set default WLS address
                    const entityAddress = Address.fromEntityLocation(order.entity.getDefaultShippingLocation());
                    if (entityAddress) {
                        orderItem.deliveries[0].setAddress(entityAddress, this.authService.user, order.isCustom, userRole);
                    }
                } else  {
                    // set user's default address
                    const availableAddresses = computeAvailableAddresses(this.addressService.addresses, order, orderItem);
                    if (!isEmptyArray(availableAddresses)) {

                        // find  default address
                        const defaultAddress  = availableAddresses.find( a => a.isDefault);
                        if (defaultAddress) {
                            orderItem.deliveries[0].setAddress(availableAddresses[0], this.authService.user, order.isCustom, userRole);
                        }
                    }
                }
            }
            this.findBudget(orderItem);
        }

        return orderItem;
    }

    private findBudget(orderItem: OrderItem) {
        const budgetId = orderItem?.financials_total?.budget_id;
        if (budgetId === -1) {
            orderItem.budgetItem = this.budgetService.emptyBudget;
        } else if (orderItem.financials_total.budget_id > 0) {
            orderItem.budgetItem = this.budgetService.budgets.find( b => b.id === orderItem.financials_total.budget_id);
        } else {
            const hardLimitBudget = this.budgetService.getHardlimitBudgetForProduct(orderItem.product);
            if (hardLimitBudget) {
                orderItem.financials_total.budget_id = hardLimitBudget.id;
                orderItem.budgetItem = hardLimitBudget;
            }
        }
    }


}
