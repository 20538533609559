import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';


import { ToastrService } from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderService} from '../../../services/order.service';

import {Address} from '../../../models/address.model';

import {Order} from '../../../models/order.model';
import {ECOMMERCE_TYPE} from '../../../constants/order-types';
import {OrderItemService} from '../../../services/order-item.service';


@Component({
    selector: 'app-manage-order-list-addresses',
    templateUrl: './manage-order-list-addresses-modal.component.html',
    styleUrls: ['./manage-order-list-addresses-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ManageOrderListAddressesComponent implements OnInit, OnDestroy {

    alive = true;
    _addressList: Address[];  // input  addresses
    editableAddressList: Address[] = [];
    inProgress = false;
    orderType: string;
    _order: Order;
    shouldUpdateShipments = false;

    constructor(
        protected toastr: ToastrService,
        private activeModal: NgbActiveModal,
        private orderService: OrderService, private orderItem: OrderItemService) {
    }


    ngOnInit() {

        this._order = this.orderType === ECOMMERCE_TYPE ? this.orderService.activeODOrder : this.orderService.activeBWOrder;
        this._addressList =  this._order.findAllOrderAddresses();
        this.editableAddressList = cloneDeep(this._addressList);
    }

    ngOnDestroy() {
        this.alive = false;
    }


    // hide and reset modal dialog
    hideModal(order?: Order) {
        this.activeModal.close(order);
    }

    get canCloseDialog(): boolean {
        return true;
    }


    get canApply(): boolean {
        return true;
    }

    onApply() {

        this.inProgress = true;

        this.orderService.updateOrderDefaultAddresses(this._order, this.editableAddressList, true)
            .subscribe( result => {
                this.inProgress = false;
                if (result) {
                    this.hideModal(result);
                } else {
                    this.editableAddressList = cloneDeep(this._addressList);
                }
            });
    }

    close() {
        this.hideModal();
    }


    // preset addresses can be editable only for new orders
    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.editableAddressList = [...addresses];
    }
}

