import {
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {from, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DOCUMENT} from '@angular/common';


import {Product} from '../../../../models/product.model';
import {OrderItemDelivery} from '../../../../models/order-item-delivery.model';
import {Order} from '../../../../models/order.model';
import {OrderItem} from '../../../../models/order-item.model';
import {OrderService} from '../../../../services/order.service';
import {AuthService} from '../../../../services/auth.service';
import {ECOMMERCE_TYPE} from '../../../../constants/order-types';
import {ConfigService} from '../../../../services/config.service';
import {AddressService} from '../../../../services/address.service';
import {Address} from '../../../../models/address.model';
import * as ORDER_STATES from '../../../../constants/order-states';
import {ITEM_COMPLETE_STATE, QUOTE_STATE} from '../../../../constants/order-states';
import {BACKORDER_ALL_ITEMS, BACKORDER_PARTIAL_ITEMS} from '../../../../constants/texts';
import {ModalService} from '../../../../services/modal.service';
import {floorLastCent, getCustomizationsTotal, isEmptyArray} from '../../../../shared/utils';
import {Customization} from '../../../../interfaces/customization';
import {OrderItemSku} from '../../../../models/order-item-sku.model';
import {TranslateService} from '../../../../services/translate.service';
import {SKU} from '../../../../models/sku.model';
import {TrackingNumber, UNKNOWN_SLUG} from '../../../../interfaces/tracking-number';
import * as TRACKING_STATES from '../../../../constants/tracking-states';
import {computeDiscountPrice, computeShipmentLabel} from '../../../../shared/helpers';
import {PaymentService} from '../../../../services/payments.service';
import {MobileCheckServiceService} from '../../../../services/mobile-check-service.service';
import {MultiTenantService} from '../../../../services/multi-tenant.service';
import {ShippingMethod} from '../../../../models/shipping-method';
import {ToastrService} from 'ngx-toastr';
import {AddressType} from '../../../../enums/address-type';

@Component({
    selector: 'app-product-order-item-delivery',
    templateUrl: './product-order-item-delivery-component.html',
    styleUrls: ['./product-order-item-delivery-component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductOrderItemDeliveryComponent implements OnInit {

    @ViewChild('divContainer') divContainer: ElementRef;

    @Input() product: Product;

    @Input() delivery: OrderItemDelivery;

    @Input() order: Order;

    @Input() orderItem: OrderItem;

    @Input() canRemoveAddress = false;

    @Input() readonly = false;

    @Input() showBudget = false;

    @Input() showEditControls = false;

    @Input() showTotalInline = false;

    @Input() isLastShipment = false;

    @Input() isFirstShipment = false;

    @Input() viewType: string;

    @Input() totals: number;

    @Input() paidQuantity: number;

    @Input() showTrackingInfo = false;

    @Input() updatingTrackingInfo = false;


    // @Output() addressRemoved = new EventEmitter<void>(); // output event emits when delivery is removed

    // @Output() quantityChanged = new EventEmitter<void>(); // output event emits when delivery quantity is changed

    // @Output() addressChanged = new EventEmitter<void>(); // output event emits when address is changed

    @Output() onRemove = new EventEmitter<OrderItem>();

    @Output() customizationChanged = new EventEmitter<void>(); // emits when customization data changed

    @Output() deliverToChanged = new EventEmitter<void>(); // emits when deliver_to field changed

    @Output() changed = new EventEmitter<OrderItem>();

    @Output() refreshStatus = new EventEmitter<void>();

    shippingMethods: any[] = [];
    isSingleShippingMethod = false;

    addresses: Address[] = [];

    private backOrderWarning = '';


    trackingNumbers: TrackingNumber[] = [];
    status: string;
    trackingData: string;
    show = 'text';

    updatingDeliveryId: number = null;

    _refreshingTrackingStatus = false;

    _shipToAddressContainerCss = '';
    _shippingMethodContainerCSS = '';
    _internalOrderContainerCss = '';
    _showInputLabel = true;

    private hasShippingMethodError  = false;
    @Input() set refreshingTrackingStatus(val: boolean) {
        if (val === false && this._refreshingTrackingStatus) {
            // redraw tracking data when refreshing is finished;
            this.initTrackingData();
        }
        this._refreshingTrackingStatus = val;
    };


    constructor( private orderService: OrderService,
                 private authService: AuthService,
                 private configService: ConfigService,
                 private addressService: AddressService,
                 private modalService: ModalService,
                 private translateService: TranslateService,
                 private ngbModalService: NgbModal,
                 private paymentService: PaymentService,
                 private mobileCheck: MobileCheckServiceService,
                 private multiTenantService: MultiTenantService,
                 @Inject(DOCUMENT) private _document: Document,
                 private toastr: ToastrService
                 ) {}


    ngOnInit(): void {

        this.calcAvailableShipmentMethods();

        this.addresses = this.addressService.addresses;

        this.detectBackorder();

        this.initTrackingData();

        this._shipToAddressContainerCss  =  this.shipToAddressContainerCss;
        this._shippingMethodContainerCSS = this.shippingMethodContainerCSS;
        this._internalOrderContainerCss = this.internalOrderContainerCss;
        this._showInputLabel = this.showInputLabel;

    }




    private initTrackingData() {
        this.trackingNumbers = this.delivery.normalizedTrackingNumbers;

        if (this.trackingNumbers.length === 0) {
            // tracking data not available
            this.trackingData = 'N/A';
            this.status = (this.order.state === ORDER_STATES.COMPLETE_STATE) ? TRACKING_STATES.DELIVERED : TRACKING_STATES.PENDING;
        } else if (this.trackingNumbers.length === 1) {
            const trackingNumber = this.trackingNumbers[0];

            // single tracking number case
            this.status = trackingNumber.slug !== UNKNOWN_SLUG ? trackingNumber.status :
                (this.order.state === ORDER_STATES.COMPLETE_STATE) ? TRACKING_STATES.DELIVERED : TRACKING_STATES.PENDING;
            this.show = 'link';
            if (this.validateTrackingNumber(this.trackingNumbers[0].number)) {
                this.show = 'vendor';
                this.trackingData = this.trackingNumbers[0].number;
            }
        } else {
            // multiple tracking case

            // find global tracking status
            this.status = (this.trackingNumbers.filter(
                t => t.status === TRACKING_STATES.DELIVERED && t.slug !== UNKNOWN_SLUG).length === this.trackingNumbers.length) ?
                TRACKING_STATES.DELIVERED : TRACKING_STATES.IN_TRANSIT;
            this.show = 'modal';

            this.trackingNumbers.forEach(tracking => {
                tracking.mode =  this.validateTrackingNumber(tracking.number) ? 'vendor' : 'link';
            });
        }

    }


    validateTrackingNumber(value: string): boolean {
        return (value === 'MISC' || value === 'Call Client Service');
    }

    showDetails(content) {
        this.ngbModalService.open(content).result.then(() => {
        }, () => {
        });
    }


    isDelivered(val): boolean {
        return val === TRACKING_STATES.DELIVERED;
    }


    /**
     * checks whether item can be purchased
     * in case of BUYING_WINDOW project  ow_purchasable == false - purchase is not available
     */
    get canPurchase(): boolean {
        if (!this.order) {
            return false;
        }

        if (this.paymentService.hasFailedTransactions) {
            return false;
        }

        return this.orderItem.deliveries.length !== 0 &&
            (  this.order.type === ECOMMERCE_TYPE ?
                ( (this.orderItem.init_quantity + this.delivery.quantity) > 0  || this.product.inStock )
                : this.product.ow_purchasable === true);
    }

    updateAddressData(address: Address) {
        if (this.canOrder && address) {

            const userRoles = {canOrder: true, isEmployee: this.authService.isEmployee};
            this.delivery.data = this.delivery.data || {};
            this.delivery.unchanged = false;
            this.delivery.setAddress(address, this.authService.user, this.order.isCustom, userRoles);
            this.delivery.method = null;
            this.calcAvailableShipmentMethods();

            this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
            this.changed.emit(this.orderItem);
            // this.addressChanged.emit();
        }
    }


    updateShippingMethod(event) {
        if (event) {
            this.delivery.method = event.method;
            this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
            this.changed.emit(this.orderItem);
        }
    }


    removeAddress() {
        this.orderItem.removeDelivery(this.delivery);
        this.changed.emit(this.orderItem);
    }

    get completed(): boolean {
        return this.orderItem.state === ITEM_COMPLETE_STATE;
    }

    onInternalOrderChanged(newValue: string) {
        const value  =  !!newValue ? newValue.trim() : '';
        if (this.order.isWBSDefined) {
            this.delivery.wbs = value;
        } else {
            this.delivery.internal_order = value;
        }
        this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
        // this.changed.emit(this.orderItem);
    }

    onInternalOrderReset() {
        this.delivery.wbs = '';
        this.delivery.internal_order = '';
        this.orderItem.updateDelivery(this.delivery, this.order.isOnDemand);
        // this.changed.emit(this.orderItem);
    }


    get internalOrderLabel(): string {
        if (this.order.isNotReal) {
            // fake order
            return 'Order Code';
        }
        return this.order.isWBSDefined ? 'WBS' : 'Internal Order';
    }


    onQuantityChanged(event) {
        const newValue: number = +event.target.value || 0;
        if (this.orderItem.isAvailable) {
            // ensure quantity is not null
            this.delivery.quantity = this.orderItem.validateDeliveryQuantity(this.delivery, newValue);
            // this.checkQuantity();

            this.detectBackorder();
        }
    }

    onQuantityBlur() {
        // emit change event only after blur event  to keep focus on input
        this.deliveryChanged();
        return true;
    }

    private deliveryChanged( ) {
        this.orderItem.changed = true;
        this.changed.emit(this.orderItem);
    }

    onHandleRemove() {
        this.onRemove.emit(this.orderItem);
    }

    private detectBackorder() {
        if (this.order.type === ECOMMERCE_TYPE) {
            const pendingQuantity = this.orderItem.getPendingQuantity();
            if (pendingQuantity) {

                const inStockQuantity = this.orderItem.getInStockQuantity();

                if (inStockQuantity === 0 && this.delivery.quantity > 0) {
                    // all items go to backorder
                    this.backOrderWarning = BACKORDER_ALL_ITEMS;
                } else if ( inStockQuantity < this.delivery.quantity) {
                    // partial items go to backorder
                    const backOrderAmount = this.delivery.quantity - inStockQuantity;
                    this.backOrderWarning = BACKORDER_PARTIAL_ITEMS.replace( '${0}', '' + backOrderAmount);
                } else {
                    this.backOrderWarning = '';
                }
            }

        }
    }

    addCustomization() {

        const data: {maxQTY: number; productCustomization: Customization;
            deliveryCustomizations: Customization[];
            hasVariations: boolean} = {
            maxQTY: this.orderItem.maxAvailableQuantity,
            productCustomization: this.orderItem.product.customization,
            deliveryCustomizations: this.delivery.customizations,
            hasVariations: this.orderItem.product.hasVariations};


        this.modalService.showProductCustomizationModal( data ).then( result => {
            if (result) {

                this.delivery.quantity = getCustomizationsTotal(result);
                this.delivery.customizations = result;
                this.orderItem.calculateQuantity();

                // this.deliveryChanged();

                this.customizationChanged.emit();
            }
        });
    }

    get canCustomize(): boolean {
        return this.order.isDecisionPoint !== true
            && this.authService.canEditOrder(this.order)
            && !this.paymentService.hasFailedTransactions;
    }
    onQuantityClicked(e: Event) {
        if (this.delivery.hasCustomization) {
            setTimeout(() => {
                this.addCustomization();
            }, 500);

        }
    }


    get canEditDeliveryTo(): boolean {
        if (this.orderItem?.restricted) {
            return false;
        }
        return this.order.state === QUOTE_STATE && this.authService.canEditOrder(this.order);
    }

    get canEditInternalOrder(): boolean {
        if (this.orderItem?.restricted) {
            return false;
        }
        return this.order.state === QUOTE_STATE && this.authService.canEditOrder(this.order) &&  this.canPurchase;
    }

    get canEditSAP(): boolean {
        return this.order.state === QUOTE_STATE && this.order.isCustom;
    }

    editInternalOrder(delivery: OrderItemDelivery) {
        if (!this.canEditInternalOrder) {
            return;
        }

        this.modalService.showInternalOrderModal(this.order, this.orderItem, delivery).then( retAttention => {
            if (retAttention) {
                this.deliverToChanged.emit();
            }
        });
    }

    editSAP(delivery: OrderItemDelivery) {
        if (!this.canEditSAP) {
            return;
        }

        const costCenter = delivery.cost_center ||  this.order.costCenter;


        from(this.modalService.showSelectSAPModal(costCenter)).pipe(
            switchMap( retCostCenter => {
                if (!retCostCenter) {
                    return of(null);
                }

                this.delivery.cost_center = retCostCenter;
                this.updatingDeliveryId = delivery.id;
                return this.orderService.updateOrderItem(this.order, this.orderItem);
            })
        ).subscribe( retOrder => {

            if (retOrder) {
                const retOrderItem = retOrder.items.find( i => i.id === this.orderItem.id);
                if (retOrderItem) {
                    const retDelivery = retOrderItem.deliveries.find( d => d.id === delivery.id);
                    if (retDelivery) {
                        this.delivery = new OrderItemDelivery(retDelivery);
                        this.deliverToChanged.emit();
                    }
                }
            }

            this.updatingDeliveryId = null;
        });
    }


    public getShipToLabel(delivery: OrderItemDelivery): string {

        let mainContactName = '';
        const address = this.addressService.addresses.find(x => x.uniqueAddressId === delivery.uniqueAddressId);
        if (address) {
            mainContactName = address.mainOrFirstContactName;
        }
        return computeShipmentLabel(delivery, mainContactName);
    }

    public getSapInfo(delivery: OrderItemDelivery): string {
        return delivery.cost_center ? delivery.cost_center.getLongAccount() : this.order.costCenter.getLongAccount();
    }

    private  calcAvailableShipmentMethods() {

        const findAvailableShippingMethodsByCountryCode =
          (shippingMethods: ShippingMethod[], countryCode: string): ShippingMethod[] | undefined => {
            if (!countryCode) {
                return [];
            }
            return shippingMethods.filter( s => s.country === countryCode);
        }

        if (!this.delivery || !this.product) {
            return;
        }

        this.hasShippingMethodError = false;
        this.isSingleShippingMethod = false;

        const availableShippingMethods =
          findAvailableShippingMethodsByCountryCode(this.product.shipping_methods, this.delivery.country_code);

        if (isEmptyArray(availableShippingMethods)) {
            this.hasShippingMethodError = true;
            this.delivery.method = null;
            return;
        }

        if (availableShippingMethods.length === 1) {
            this.isSingleShippingMethod = true;
        }



        if (!this.delivery.method) {
            // only  if method is not set yet
            this.delivery.method = availableShippingMethods[0].method;
        }


        this.shippingMethods =  availableShippingMethods;
    }


    getVariationLabel(sku: OrderItemSku): string {
        const productSku = this.getProductSku(sku);
        if (!productSku) {
            return;
        }

        const labels = productSku.attributes.sort( (a, b) => {
            return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        }).map( attribute => {
            const label = this.translateService.translate(attribute.label);
            return `${label}: ${attribute.options[0].label}`;
        });

        return labels.join(', ');

    }

    getVariationPrice(sku: OrderItemSku): number {
        let price = 0;
        const productSKU = this.getProductSku(sku);
        if (!productSKU) {
            return price;
        }

        price  = Number(productSKU.product_price || 0);

        return floorLastCent(computeDiscountPrice(price, this.discount));
    }

    getVariationTotal(sku: OrderItemSku): number {
        const productSKU = this.getProductSku(sku);

        if (!productSKU) {
            return 0;
        }

        const price = this.getVariationPrice(sku);
        const skuQuantity = sku?.quantity || 0;
        return floorLastCent(price * skuQuantity);
    }


    private getProductSku(sku: OrderItemSku): SKU {
        if (!sku) {
            return;
        }

        return this.orderItem.product.skus.find( s => s.id === sku.sku_id);
    }

    showDPFaqDialog() {
        this.modalService.showDecisionPointFaq(this.order.attr_decision_point_name);
    }

    get discount(): number {
        if (this.orderItem) {
            return this.orderItem.productDiscount;
        }

        return this.product?.discount || 0;
    }

    get canOrder(): boolean {
        return this.authService.canOrder;
    }

    get mobile(): boolean {
        return this.mobileCheck.mobile;
    }

    get mobileDevice(): boolean {
        return this.mobileCheck.mobileDevice;
    }


    get showInputLabel(): boolean {
        if (this.tideContainer) {
            return true;
        }
        return this.isFirstShipment;
    }


    get tideContainer(): boolean {
        const modal  = this._document.querySelector('.order-item-modal');
        if (!!modal) {
            return true;
        }

        if (this.divContainer) {
            return this.divContainer.nativeElement.clientWidth < 665;
        }
        return false;
        // const lg = window.matchMedia('(max-width: 1199px)');
        // return lg.matches;
    }


    get qtyContainerCSS(): string {
        const styles: string[] = [];

        if (this.viewType === 'list-item') {
            styles.push('list-item');
        }

        // if (this.deliveryColumns === 2) {
        //     styles.push('half-width');
        // }

        if (this.canShowInternalOrder) {
            styles.push('col-2');
        } else {
            styles.push('col-3');
        }

        if (this.mobile) {
            styles.push(' px-0 pr-2');
        }

        return styles.join(' ');
    }

    get shippingMethodContainerCSS(): string {
        const styles: string[] = [];

        if (this.viewType === 'list-item') {
            styles.push('list-item');
        }

        styles.push('col-3');

        if (this.mobile) {
            styles.push(' px-0 pr-2');
        }

        return styles.join(' ');
    }


    get shipToAddressContainerCss(): string {
        const styles: string[] = [];

        if (this.viewType === 'list-item') {
            styles.push('list-item');
        }
        if (this.tideContainer) {
            styles.push('col-5');
        } else {
            styles.push('col-4');
        }

        if (this.mobile) {
            styles.push('px-0');
        }

        return styles.join(' ');
    }

    get internalOrderContainerCss(): string {
        const styles: string[] = [];
        if (this.viewType === 'list-item') {
            styles.push('list-item');
        }

        if (this.canShowInternalOrder) {
            if (this.tideContainer) {
                styles.push('full-width');
            } else {
                styles.push('col-3');
            }
        }

        if (this.mobile) {
            styles.push('px-0');
        }

        return styles.join(' ');
    }


    get canShowInternalOrder(): boolean {
        return this.multiTenantService.canShowWBS;
    }

    findMethodLabel(product: Product, delivery: OrderItemDelivery): string {
        if (!product || !delivery) {
            return '';
        }

        const method = delivery.method;
        const addressCountry = delivery.country_code;
        let shippingMethod = null;
        if (method || addressCountry) {
            shippingMethod = product.shipping_methods.find( m => m.method === method && m.country === addressCountry);
        }

        return shippingMethod ? shippingMethod.method_label : '';
    }
}
